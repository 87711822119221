import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import IframeComm from 'react-iframe-comm';
import API from '../../services/Services';
import Footer from '../../components/Footer';
import GoTop from '../../components/GoTop';
import moment from 'moment';
import ReactPixel from 'react-facebook-pixel';
import Header from '../../components/Header';

const Loading = () => {
  return (
    <div className="lds-css ng-scope">
      <div
        style={{
          width: '100%',
          height: '100%',
        }}
        className="lds-ellipsis"
      >
        <div>
          <div></div>
        </div>
        <div>
          <div></div>
        </div>
        <div>
          <div></div>
        </div>
        <div>
          <div></div>
        </div>
        <div>
          <div></div>
        </div>
      </div>
    </div>
  );
};
class ConfirmationPurchase extends Component {
  state = {
    imgEvent: '',
    zonas: [],
    confirmacion: {},
    attrGoogleIframe: {
      src: '',
      width: '100%',
      height: '232px',
      frameBorder: 0,
      allowFullScreen: true,
    },
    urlCalendar: '',
    loadingCalendar: false,
    disabledCalendar: false,
    loadingSubscribe: false,
    disabledSubscribe: false,
  };

  async componentDidMount() {
    if(this.props.location.state.selectedFunction.hasOwnProperty("FuncionPixelFacebook") && this.props.location.state.selectedFunction.FuncionPixelFacebook){
      ReactPixel.init(this.props.location.state.selectedFunction.FuncionPixelFacebook, {
        autoConfig: true,
        debug: true,
      });
      ReactPixel.pageView();
      ReactPixel.track('PageContent');
    }
    const { transaccion } = this.props.match.params;
    let obj = this.props.location.state
      ? this.props.location.state.confirmacion
      : null;
    if (obj === null || obj === undefined) {
      const { status, data } = await API.pay.obtenerConfirmacion(transaccion);
      if (status === 200) {
        obj = data;
      } else {
        //error cargando
      }
    }
    this.setState({ confirmacion: { ...obj } }, () => {
      this.setState({
        imgEvent:`https://ticketmundoblob.blob.core.windows.net/imagenestmusaprueba/Evento_${this.state.confirmacion.EventoId}_517_262.jpeg`
      });
      const zonas = obj.EntradasDetallada.reduce((result, current) => {
        (result[current['Zona']] = result[current['Zona']] || []).push(current);
        return result;
      }, {});
      const zonasagrupadas = [];
      Object.keys(zonas).forEach((key) => {
        zonasagrupadas.push({ nombre: key, items: zonas[key] });
      });

      this.setState({
        zonas: zonasagrupadas,
        attrGoogleIframe: {
          ...this.state.attrGoogleIframe,
          src: `https://www.google.com/maps/embed/v1/place?key=${
            process.env.REACT_APP_GOOGLE_API
          }&q=${obj.DireccionSinZipcode.replace(' ', '+')}`,
        },
        urlCalendar: `https://calendar.google.com/calendar/r/eventedit?text=${
          obj.EventoNombre
        }&date=${moment(obj.FechaFuncion).format('YYYYMMDDTHHmmss')}Z&details=${
          obj.EventoNombre
        }&location=${obj.Direccion}`,
      });
      this.props.resetEvent();
    });
  }

  render() {
    const { global, t } = this.props;
    const { imgEvent, confirmacion, zonas, attrGoogleIframe } = this.state;
    let bannersHtml = <div className="valores clear-fl">
      <article>
        <span className="align_left_banner_donation">
          <img
            src={
              confirmacion.MontoDonacion > 0
                ? global.BannerConfirmacionDonacion
                : global.BannerConfirmacion3
            }
            alt=""
          />
        </span>
      </article>
      <article>
        <div className="">
          <span>
            <img src={global.BannerConfirmacion4} alt="" />
          </span>
        </div>
      </article>
    </div>

    let filaAporte = ""
    let linkTurismo = ""
    // console.log("global.linkTurismo",global)
    if((parseInt(global.Canalid,10) == 15 || parseInt(global.Canalid,10) === 18) && confirmacion.MontoDonacion > 0){
      bannersHtml = <div className="valores clear-fl va_middle">
        <article>
          <span className="align_left_banner_donation">
            <img
              src={
                confirmacion.MontoDonacion > 0
                  ? global.BannerConfirmacionDonacion
                  : global.BannerConfirmacion3
              }
              alt=""
            />
          </span>
        </article>
        <article>
          <b>
            Gracias por tu aporte y por colaborar con Fundación Teatro del Lago para impulsar la creatividad e innovación de niños, jóvenes y adultos que participan de nuestra misión educativa.
            <br></br>
            <br></br>
            ¡Gracias a tu contribución, el teatro-escuela crece!
          </b>
        </article>
      </div>
      filaAporte =<b className="mt_app_donation">Aporte donación {confirmacion.OrganizacionDonacionNombre}: {confirmacion.MontoDonacionString} </b>
    }
    if(global.linkTurismo !== null){
      linkTurismo = <div className='cont_link_turismo'> 
        <a className="link_turismo" target="_blank" href={global.linkTurismo}>{global.textTurismo}</a>
      </div>
    }

    return (
      <>
        <section className="confirmacion">
          <GoTop/>
          <Header confirmation={true} customer={confirmacion.TarjetaHabiente} email={confirmacion.EmailTo}/>
          <section id="wrap-ppal-9">
            <div id="wrap-cont">
              <div className="container-custom-tm clear-fl">
                <div id="wrap-pago-realizado">
                  <div className="wrap-orden">
                    <div className="datos-o clear-fl color-titulos">
                      <div>
                        <div className="title title-bold-18 nro_ord">
                          {t('order')}
                          <strong>#TR{confirmacion.Orden}</strong>
                        </div>
                        <img src={imgEvent} alt="" />
                      </div>
                      <div>
                        <div className="d-item">
                          <div>
                            <div className="title title-bold-18">
                              {confirmacion.EventoNombre}
                            </div>
                            <div className="fecha">
                              {confirmacion.FechaFuncion}
                            </div>
                            <div className="dir">
                              {confirmacion.RecintoNombreCiudad}
                            </div>
                          </div>
                          <div>
                            <div className="title title-bold-18">
                              {t('tickets')}
                            </div>
                            <div className="fecha"></div>
                            <div className="dir">
                              <span className="size14 fontregular">
                                {zonas.map((zona, index) => {
                                 
                                  return (
                                    <div key={index}>
                                      {`(${zona.items.length}) ${zona.nombre}`}
                                      {zona.items
                                        .map((item) => {
                                          let fila = item.Fila !=='-'? `:${item.Fila}`:'';
                                          let puesto = item.Puesto !=='-'? `-${item.Puesto}`:'';
                                          return `${fila}${puesto} `;
                                        })}
                                    </div>
                                  );
                                })}
                              </span>
                            </div>
                            {filaAporte}
                          </div>
                          <div className="news-letter">
                            <div className="wrap clear-fl">
                              <div>
                                <a
                                  href="#"
                                  id="suscribe"
                                  className="color-ppal"
                                  onClick={(e) => {
                                    if (!this.state.disabledSubscribe) {
                                      this.setState(
                                        { loadingSubscribe: true },
                                        () => {
                                          setTimeout(() => {
                                            this.setState({
                                              loadingSubscribe: false,
                                              disabledSubscribe: true,
                                            });
                                          }, 1000);
                                        }
                                      );
                                    } else {
                                      e.preventDefault();
                                    }
                                  }}
                                >
                                  {this.state.loadingSubscribe ? (
                                    <Loading />
                                  ) : (
                                    <>
                                      <i
                                        className={`icon-active-color ${
                                          !this.state.disabledSubscribe
                                            ? 'icon-mail'
                                            : 'icon-check-sel'
                                        }`}
                                      ></i>
                                      <span>
                                        {!this.state.disabledSubscribe
                                          ? t('subscribe')
                                          : t('newsletterAdded')}
                                      </span>
                                    </>
                                  )}
                                </a>
                              </div>
                              <div>
                                <a
                                  href={this.state.urlCalendar}
                                  id="addCalendar"
                                  className="color-ppal"
                                  target={
                                    !this.state.disabledCalendar
                                      ? '_blank'
                                      : '_self'
                                  }
                                  onClick={(e) => {
                                    if (!this.state.disabledCalendar) {
                                      this.setState(
                                        { loadingCalendar: true },
                                        () => {
                                          setTimeout(() => {
                                            this.setState({
                                              loadingCalendar: false,
                                              urlCalendar: '#',
                                              disabledCalendar: true,
                                            });
                                          }, 1000);
                                        }
                                      );
                                    } else {
                                      e.preventDefault();
                                    }
                                  }}
                                >
                                  {this.state.loadingCalendar ? (
                                    <Loading />
                                  ) : (
                                    <>
                                      <i
                                        className={`icon-active-color ${
                                          !this.state.disabledCalendar
                                            ? 'icon-calendar'
                                            : 'icon-check-sel'
                                        }`}
                                      ></i>
                                      <span>
                                        {!this.state.disabledCalendar
                                          ? t('addCalendar')
                                          : t('eventAdded')}
                                      </span>
                                    </>
                                  )}
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {bannersHtml}
                    <div className="wrap-mapa">
                      <div className="s-title sub-title-bold color-titulos">
                        {t('where')}
                      </div>
                      <p className="color-texto-contenido">
                        {confirmacion.DireccionSinZipcode}
                      </p>
                      {linkTurismo}
                      <IframeComm attributes={attrGoogleIframe} />
                    </div>
                    <div className="redes-f clear-fl">
                      <article>
                        <div className="wrap">
                          <a href={global.BannerConfirmacion1url}>
                            <img src={global.BannerConfirmacion1} alt="" />
                          </a>
                        </div>
                      </article>
                      <article>
                        <div className="wrap">
                          <a href={global.BannerConfirmacion2url}>
                            <img src={global.BannerConfirmacion2} alt="" />
                          </a>
                        </div>
                      </article>
                    </div>
                    {/* <div className="contact color-titulos">
                      <p>{t('questions')}</p>
                      <i className="icon-tel icon-active-color"></i>
                      <div className="number color-ppal">
                        {global.ATCTelefono}
                      </div>
                      <span>{global.ATCline1}</span>
                      <span>{global.ATCline2}</span>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
        <Footer />
      </>
    );
  }
}
export default withTranslation()(ConfirmationPurchase);
