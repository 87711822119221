import React from "react";
import { withTranslation } from "react-i18next";

import CalendarItem from "./CalendarItem";
import $ from "jquery";
import moment from "moment";
import { Link } from "react-router-dom";
import CalendarPicker from "./CalendarPicker";
import { currencyFormatDE } from "../../../utils/globals";

class CalendarFix extends React.Component {
    state = {
        toggleList: false,
        funcNameSelected: "",
        selectedDay: "",
        selectedIndexFunction: "",
        selectedFunction: {},
    };
    componentDidMount() {
        $(window).scroll(function (event) {
            //$('html, body').animate({scrollTop:$(window).scrollTop() + ($("#wrap-pago .info-pago .metodos").offset().top - $(window).scrollTop())}, 'slow');
            if ($("body#evento #main .wrap-evento .descripcion").length > 0) {
                var top =
                    $("body#evento #main .wrap-evento .descripcion").offset()
                        .top - $(window).scrollTop();
                // if (top <= 0 && !$(".calendar-fix").hasClass("show")) {
                //     $(".calendar-fix").addClass("show");

                //     $(".wrap-evento .calendar .wrap-opciones").css(
                //         "display",
                //         "none"
                //     );
                //     $(
                //         ".wrap-evento .calendar .fechas-estandar, .calendar"
                //     ).removeClass("active");
                //     // $(".wrap-evento .calendar i")
                //     //     .removeClass("icon-angle-d")
                //     //     .addClass("icon-calendar");
                // } else if (top >= 0 && $(".calendar-fix").hasClass("show")) {
                //     $(".calendar-fix").removeClass("show");
                //     $(".calendar-fix .calendar .wrap-opciones").css(
                //         "display",
                //         "none"
                //     );
                //     $(
                //         ".calendar-fix .calendar .fechas-estandar, .calendar"
                //     ).removeClass("active");
                //     // $(".calendar-fix .calendar i")
                //     //     .removeClass("icon-angle-d")
                //     //     .addClass("icon-calendar");
                // }
            }
        });
    }
    render() {
        const {
            functions,
            event,
            eventData,
            hourFormat,
            hourUnit,
            selectedFunction,
            onClickItem,
            isMultiCity,
            isCalendarMultiple,
            t,
        } = this.props;
        const nameToUrl = selectedFunction?.EventoNombre.replace(/\s/g, "-")
            .normalize("NFD")
            .replace(/[#?+&'\u0300-\u036f]/g, "")
            .toLowerCase();

        return (
            <div className="calendar-fix">
                <div className="wrap container-custom-tm">
                    <span>
                        <div className="title title-banner-tabla">
                            {event.Nombre}
                        </div>
                        {functions && functions.length === 1 ? (
                            <div className="calendar  unico">
                                <div className="fechas-estandar color-titulos">
                                    <div className="item sel">
                                        {moment
                                            .utc(selectedFunction.Fecha)
                                            .format(
                                                "dddd DD MMMM, " +
                                                    (hourFormat === "12hr"
                                                        ? "hh:mm"
                                                        : "HH:mm")
                                            )}{" "}
                                        Hrs
                                    </div>
                                    <i
                                        className={"color-ppal icon-calendar"}
                                    ></i>
                                    {/* <i
                                        className={`icon-angle-d`}
                                        style={
                                            this.state.toggleList && functions.length > 1
                                                ? { transform: "rotate(180deg)" }
                                                : {}
                                        }
                                    ></i> */}
                                </div>
                            </div>
                        ) : !isCalendarMultiple ? (
                            <div className="calendar multiple 1">
                                <div className="fechas-estandar color-titulos">
                                    <div
                                        className="item sel"
                                        onClick={() => {
                                            this.setState({
                                                toggleList:
                                                    !this.state.toggleList,
                                            });
                                        }}
                                    >
                                        {selectedFunction
                                            ? `${moment
                                                  .utc(selectedFunction.Fecha)
                                                  .format(
                                                      "dddd DD MMMM, " +
                                                          (hourFormat === "12hr"
                                                              ? "hh:mm"
                                                              : "HH:mm")
                                                  )} Hrs`
                                            : this.props.global.LabelVenir &&
                                              this.props.global.LabelVenir.trim() !==
                                                  ""
                                            ? this.props.global.LabelVenir
                                            : t("chooseDateVenue")}
                                    </div>
                                    {this.state.toggleList && (
                                        <div className="wrap-opciones">
                                            {functions &&
                                                functions.map((func) => {
                                                    const funcNameToUrl =
                                                        func.EventoNombre.replace(
                                                            /\s/g,
                                                            "-"
                                                        )
                                                            .normalize("NFD")
                                                            .replace(
                                                                /[#?+&'\u0300-\u036f]/g,
                                                                ""
                                                            )
                                                            .toLowerCase();
                                                    func = {
                                                        ...func,
                                                        funcNameToUrl:
                                                            funcNameToUrl,
                                                    };
                                                    return (
                                                        <CalendarItem
                                                            key={func.FuncionId}
                                                            item={func}
                                                            hourFormat={
                                                                hourFormat
                                                            }
                                                            hourUnit={hourUnit}
                                                            onClickItem={() => {
                                                                onClickItem(
                                                                    func
                                                                );
                                                                this.setState({
                                                                    toggleList:
                                                                        !this
                                                                            .state
                                                                            .toggleList,
                                                                });
                                                                window.history.pushState(
                                                                    null,
                                                                    null,
                                                                    `${func.EventoID}?selectedFunctionId=${func.FuncionId}`
                                                                );
                                                            }}
                                                            isMultiCity={
                                                                isMultiCity
                                                            }
                                                        />
                                                    );
                                                })}
                                        </div>
                                    )}
                                </div>
                                <i className="icon-calendar color-ppal"></i>
                                <i
                                    className={`icon-angle-d`}
                                    style={
                                        this.state.toggleList &&
                                        functions.length > 1
                                            ? { transform: "rotate(180deg)" }
                                            : {}
                                    }
                                ></i>
                            </div>
                        ) : (
                            <div className="calendar multiple 2">
                                <div className="fechas-estandar color-titulos">
                                    <div
                                        className="item sel"
                                        onClick={() => {
                                            this.setState({
                                                toggleList:
                                                    !this.state.toggleList,
                                            });
                                        }}
                                    >
                                        {selectedFunction
                                            ? `${moment
                                                  .utc(selectedFunction.Fecha)
                                                  .format(
                                                      "dddd DD MMMM, " +
                                                          (hourFormat === "12hr"
                                                              ? "hh:mm"
                                                              : "HH:mm")
                                                  )} Hrs`
                                            : this.props.global.LabelVenir &&
                                              this.props.global.LabelVenir.trim() !==
                                                  ""
                                            ? this.props.global.LabelVenir
                                            : t("chooseDateVenue")}
                                    </div>
                                    {this.state.toggleList && (
                                        <CalendarPicker
                                            functions={functions}
                                            hourFormat={hourFormat}
                                            hourUnit={hourUnit}
                                            onClickCollapsablePanel={() => {
                                                this.setState({
                                                    toggleList:
                                                        !this.state.toggleList,
                                                });
                                            }}
                                            onClickItem={onClickItem}
                                        />
                                    )}
                                </div>
                                <i className="icon-calendar color-ppal"></i>
                                <i
                                    className={`icon-angle-d`}
                                    style={
                                        this.state.toggleList &&
                                        functions.length > 1
                                            ? { transform: "rotate(180deg)" }
                                            : {}
                                    }
                                ></i>
                            </div>
                        )}
                    </span>
                    <span>
                        {selectedFunction && (
                            <div className="precio">
                                <span>
                                    {selectedFunction.MonedaSimbolo
                                        ? event.SubBotonTitle &&
                                        event.SubBotonTitle.trim() !== ""
                                        ? event.SubBotonTitle.toUpperCase()
                                        : this.props.t("from").toUpperCase()
                                        : ""}
                                </span>
                                <p>
                                    {" "}
                                    {`${selectedFunction.MonedaSimbolo || ""} ${
                                        selectedFunction.MenorPrecio &&
                                        currencyFormatDE(
                                            selectedFunction.MenorPrecio,
                                            this.props.thousandSeparator,
                                            this.props.decimalSeparator
                                        )
                                    } ${
                                        eventData && !eventData.currencyMulti
                                            ? ""
                                            : selectedFunction.Iso
                                    }`}
                                </p>
                            </div>
                        )}

                        <Link
                            to={
                                selectedFunction
                                    ? `/show/${nameToUrl}/${selectedFunction.FuncionId}`
                                    : "#"
                            }
                        >
                            <button
                                className={`button-book btn-ppal ${
                                    selectedFunction ? "active" : ""
                                }`}
                            >
                                {t("buyTickets")}
                            </button>
                        </Link>
                    </span>
                </div>
            </div>
        );
    }
}

export default withTranslation()(CalendarFix);
