import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  FacebookShareButton,
  EmailShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";

const ModalShare = ({ handleClose, show, linkFunc }) => {
  const { t } = useTranslation();
  const [linkShared, setLinkShared] = useState(false);
  const notify = (text) => {
    //toast('Se copio elemento en el portapapeles');
    toast.info(t("copyLinkToClipboardSuccess"), {
      position: toast.POSITION.BOTTOM_CENTER,
    });
    setLinkShared(true);
  };

  useEffect(() => {
    setLinkShared(false);
  }, []);

  const showHideClassName = show ? "in" : "";
  return (
    <div
      className={`modal fade modalShare ${showHideClassName}`}
      style={{ display: show ? "block" : "none" }}
      id="modalShare"
      role="dialog"
    >
      <div
        className="modal-dialog modal-dialog-centered modal-md"
        role="document"
      >
        <div className="modal-content">
          <div className="conten-share clear-fl">
            <div
              className="cerrar cerrar-shared"
              data-dismiss="modal"
              aria-label="Close"
            >
              <i className="icon-cerrar-simple" onClick={handleClose}></i>
            </div>
            <div className="content">
              <div className="title sub-title-bold">{t("share")}</div>
              <div className="redes">
                <div>
                  <FacebookShareButton url={linkFunc}>
                    <img src="/images/evento/face.svg" alt="" />
                  </FacebookShareButton>
                </div>
                {/* <div>
                                    <img src="/images/evento/messenger.svg" alt="" />
                                </div> */}
                <div>
                  <LinkedinShareButton url={linkFunc}>
                    <img src="/images/evento/linkedin.svg" alt="" />
                  </LinkedinShareButton>
                </div>
                <div>
                  <TwitterShareButton url={linkFunc}>
                    <img src="/images/evento/twitter.svg" alt="" />
                  </TwitterShareButton>
                </div>
                <div>
                  <EmailShareButton url={linkFunc}>
                    <img src="/images/evento/mail.svg" alt="" />
                  </EmailShareButton>
                </div>
              </div>
              <div className="link">
                <span>{t("copyLinkText")}</span>
                <label>
                  <label className="link-to-copy">{linkFunc}</label>
                  <CopyToClipboard text={linkFunc}>
                    <button onClick={() => notify(linkFunc)}>
                      {linkShared
                        ? t("copyLinkToClipboardSuccess")
                        : t("copyLinkToClipboard")}
                    </button>
                  </CopyToClipboard>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalShare;
