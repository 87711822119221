import React,{useEffect,useState} from 'react';
import img from '../images/confirm.svg'
import {
  currencyFormatDE,
} from '../utils/globals';
import moment from 'moment';
import Footer from "../components/Footer";
const succesPage = ({location})=>{
//   console.log("llegue",location.state)
  /*useEffect(() => {
    document.getElementById("root").style.background="#FAFAFA"
    return ()=>{
      document.getElementById("root").style.background="#fff"
    }
  })
  if(!location.state){
    return (<h4>Error en la página solicitada</h4>)
  }*/
  
    window.scroll({
        top: 0,
        behavior: "smooth"
    });

  return(
    <>
        <section className='sp_bg'>
            <div className="payment_succes_header">
                <img src="https://ticketmundoblob.blob.core.windows.net/skin/logo_20.jpeg" width="249" alt="" />
                <img src="https://cdn.ticketmundo.live/commons/cuota.gif" width="90" alt="cuota" />
                <div className='text_header'>
                    <span style={{fontSize: '40px'}}>{location.state.Nombre}, hemos recibido la primera cuota de tu compra</span>
                    <span> y realizamos una reserva de tu orden</span>
                    <span style={{fontSize: '15px',fontWeight: '400'}}> se enviaron detalles al correo</span>
                    <span>{location.state.Email}</span>
                </div>
            </div>
            <section className="container-custom-tm clear-fl wrap-pago wrap_succes_payment">
                <div className='content'>
                    <div className="title-g sub-title-bold color-titulos">
                        <span>Nos complace informarte que tu entrada ha sido reservada con éxito</span>
                    </div>
                    <div className='white_cont' >
                        Como has optado por nuestro plan de pago en cuotas queremos redordarte las fechas limite para cada una de tus cuotas,<br></br>
                        <b>recibiras un correo correpondiente al pago de cada cuota.</b>
                        <ul className='ul_tickets' style={{marginTop: "10px"}}>
                            {
                                location.state.Cuotas.Cuotas.map((cuota,key)=>{
                                    let pill = <span className='pill' style={{background: "#ABAAB2", color:"#fff",border:`1px solid #ABAAB2`}}>Inicial</span>
                                    if(key === 1){
                                        pill = <span className='pill' style={{background: "#5CC8CA", color:"#fff"}}>Cuota {cuota?.NumCuota-1}</span>
                                    }else if(key >1){
                                        pill = <span className='pill'>Cuota {cuota?.NumCuota-1}</span>
                                    }
                                    
                                    return <li key={key}>
                                        {pill}
                                        <div> { cuota?.FechaCorte?.split(" ")[0]}</div>
                                        <div> { key === 0 ? `Pagado`:`Pendiente` }</div>
                                        <b>{ location?.state?.global?.MonedaPorDefecto} {cuota?.Monto}</b> 
                                    </li>
                                })
                            }
                        </ul>
                    </div>
                    <div className="title-g sub-title-bold color-titulos">
                        <span>Términos y condiciones de servicio</span>
                    </div>
                    <div className='white_cont'>
                        Te estaremos contactando vía correo con un link de pago para que pagues tu próxima cuota, es
                        importante que realices el pago en las siguientes 24 horas luego de recibir el correo para
                        mantener tu reserva. Te invitamos a revisar las condiciones:
                        <br/><br/>
                        <b>1. Reserva de Entrada</b> 
                        <ul className='_ul2'>
                            <li>1.1. Al realizar el primer pago, el cliente reserva su entrada para el evento especificado</li>
                            <li>1.2. La reserva no garantiza la entrada al evento. Cuando hayas completo el pago total de tu compra, te enviaremos tu entrada al correo registrado.</li>
                        </ul>

                        <b>2. Cuotas y Plazos</b> 
                        <ul className='_ul2'>
                            <li>2.1. El cliente se compromete a cumplir con las cuotas según el calendario establecido.</li>
                            <li>2.2. Las fechas de vencimiento para las cuotas se especificarán en el momento de la reserva y en cualquier comunicación posterior.</li>
                            <li>2.3. Si el cliente no realiza el pago de una cuota en la fecha indicada, su reserva será cancelada.</li>
                        </ul>
                        Te recomendamos estar atento a tu correo para realizar tu pago a tiempo y mantener la reserva.
                        <br/>
                        Si tienes alguna pregunta o necesitas ayuda con el proceso de pago, no dudes en ponerte en
                        contacto con nosotros vía whatsapp al <a href='https://wa.me/+584124286237' target="_blank" className='linkes'><svg xmlns="http://www.w3.org/2000/svg" fill="#25D366" height="19px" width="19px" version="1.1" viewBox="0 0 308 308" >
                                <path d="M227.904,176.981c-0.6-0.288-23.054-11.345-27.044-12.781c-1.629-0.585-3.374-1.156-5.23-1.156   c-3.032,0-5.579,1.511-7.563,4.479c-2.243,3.334-9.033,11.271-11.131,13.642c-0.274,0.313-0.648,0.687-0.872,0.687   c-0.201,0-3.676-1.431-4.728-1.888c-24.087-10.463-42.37-35.624-44.877-39.867c-0.358-0.61-0.373-0.887-0.376-0.887   c0.088-0.323,0.898-1.135,1.316-1.554c1.223-1.21,2.548-2.805,3.83-4.348c0.607-0.731,1.215-1.463,1.812-2.153   c1.86-2.164,2.688-3.844,3.648-5.79l0.503-1.011c2.344-4.657,0.342-8.587-0.305-9.856c-0.531-1.062-10.012-23.944-11.02-26.348   c-2.424-5.801-5.627-8.502-10.078-8.502c-0.413,0,0,0-1.732,0.073c-2.109,0.089-13.594,1.601-18.672,4.802   c-5.385,3.395-14.495,14.217-14.495,33.249c0,17.129,10.87,33.302,15.537,39.453c0.116,0.155,0.329,0.47,0.638,0.922   c17.873,26.102,40.154,45.446,62.741,54.469c21.745,8.686,32.042,9.69,37.896,9.69c0.001,0,0.001,0,0.001,0   c2.46,0,4.429-0.193,6.166-0.364l1.102-0.105c7.512-0.666,24.02-9.22,27.775-19.655c2.958-8.219,3.738-17.199,1.77-20.458   C233.168,179.508,230.845,178.393,227.904,176.981z"/>
                                <path d="M156.734,0C73.318,0,5.454,67.354,5.454,150.143c0,26.777,7.166,52.988,20.741,75.928L0.212,302.716   c-0.484,1.429-0.124,3.009,0.933,4.085C1.908,307.58,2.943,308,4,308c0.405,0,0.813-0.061,1.211-0.188l79.92-25.396   c21.87,11.685,46.588,17.853,71.604,17.853C240.143,300.27,308,232.923,308,150.143C308,67.354,240.143,0,156.734,0z    M156.734,268.994c-23.539,0-46.338-6.797-65.936-19.657c-0.659-0.433-1.424-0.655-2.194-0.655c-0.407,0-0.815,0.062-1.212,0.188   l-40.035,12.726l12.924-38.129c0.418-1.234,0.209-2.595-0.561-3.647c-14.924-20.392-22.813-44.485-22.813-69.677   c0-65.543,53.754-118.867,119.826-118.867c66.064,0,119.812,53.324,119.812,118.867   C276.546,215.678,222.799,268.994,156.734,268.994z"/>
                            </svg> +58 (0412) 4286237
                        </a> o al correo electrónico <a href='mailto:atencionalcliente@ticketmundo.com' target="_blank" className='linkes'>atencionalcliente@ticketmundo.com</a>
                        <br/><br/>
                        ¡Nos vemos allá!
                    </div>
                    <br/><br/>
                </div>
                              
                <div className='resumen'>
                    <div className="title-g sub-title-bold color-titulos">
                        <span>Detalle de los tickets</span>
                    </div>
                    <div className='white_cont'>
                        <img src={`https://ticketmundoblob.blob.core.windows.net/imagenestmusaprueba/Evento_${location.state.selectedFunction.EventoId}_517_262.jpeg`} alt="" className='imag_pay'/>
                        <div className="title-g sub-title-bold color-titulos">
                            <span>{location.state.selectedFunction.EventoNombre.toLowerCase()}</span>
                            <p style={{fontWeight: '400',fontSize: '15px',lineHeight: '20px'}}>{moment.utc(location.state.selectedFunction.Fecha).format("dddd DD MMMM")} {moment.utc(location.state.selectedFunction.Fecha).format(location.state.global.formatoFecha ==="12hr"? "hh:mm": "HH:mm") +" hrs"}</p>
                            <p style={{fontWeight: '400',fontSize: '15px',lineHeight: '20px'}}>{location.state.selectedFunction.RecintoNombre} </p>
                        </div>
                        <p>Vip 2</p>
                    </div>
                </div>
            </section>
        </section>
        <Footer />
    </>
  )
}

export default succesPage;