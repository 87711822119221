import React, { Component, Fragment } from "react";
import { withTranslation } from "react-i18next";
import ReactPixel from "react-facebook-pixel";
import Services from "../../../../services/Services";
import {
    currencyFormatDE,
    currencyFormatDonation,
    currencyFormatValue,
} from "../../../../utils/globals";
import CurrencyInput from "../../../../components/CurrencyInput";
import "./resumePay.css";
import CarTop from "../../../Tickets/tienda/components/car/carTop";

class ResumePay extends Component {
    state = {
        liberarTicketsSelected: false,
        liberarTicketsSelectedFreeAssignation: false,
        liberarSelected: undefined,
        donacionIsShown: false,
        promoCodeIsShown: true,
        donaciones: [],
        donacionesMontos: [],
        donacionesLearnMore: false,
        promoCode: "",
        promoCodeValidated: undefined,
        donationSelected: undefined,
        donationSelectedInput: undefined,
        donationInputToShow: undefined,
        isLoading: false,
        donacionesNewMontos: [],
        selectedDonationId: 0,
        selectedDonationOtros: false,
        tipoEntrada: "GENERAL",
        comision: 0,
        precioTotal: 0,
        MontoTienda: 0,
    };

    handleChangePromoCode = (e) => {
        this.setState({ promoCode: e.target.value });
    };

    validarPromoCode = async () => {
        try {
            this.setState({ isLoading: true });
            const { showID, detalleRegistro, global } = this.props;
            const { promoCode } = this.state;

            const res = await Services.promocode.valPromoCode({
                Codigo: promoCode,
                FuncionId: showID,
                DetalleRegistroId: detalleRegistro.Id,
            });
            //debugger;
            if (res.status === 200) {
                if (res.data.Valido) {
                    if (!this.props.global.MostrarDecimales) {
                        res.data.MontoDescuento = Math.floor(
                            res.data.MontoDescuento
                        );
                    }
                    this.setState({
                        promoCodeValidated: res.data,
                        promoCodeIsShown: !this.state.promoCodeIsShown,
                        isLoading: false,
                    });
                    const amount = this.totalByTickets();
                    this.props.updateAmountLuka(
                        amount -
                            res.data.MontoDescuento +
                            (this.state.donationSelected || 0)
                    );
                    const paymentMethods = global.paymentMethods.split(",");
                    if (
                        paymentMethods.some(
                            (name) => name.toUpperCase() === "PAYU"
                        )
                    )
                        this.props.updateAmountPayu();
                } else {
                    this.setState({
                        promoCodeValidated: {Valido:false},
                        promoCode: "",
                        isLoading: false,
                    });
                }
            }
        } catch (error) {
            console.error(error);
            this.setState({ isLoading: false });
        }
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.reservationSeats !== this.props.reservationSeats) {
            if (this.props.reservationSeats.length === 0) {
                // window.location.reload(true);
                this.props.back();
            }
        }
        if (prevProps.carProds !== this.props.carProds) {
            this.calculateAmoutShop();
        }
        if (prevState.MontoTienda !== this.state.MontoTienda) {
            if (!document.getElementById("luka-form")) {
                return;
            }
            const amount = this.totalByTickets() + this.state.MontoTienda;
            this.props.updateAmountLuka(
                amount + (this.state.donationSelected || 0)
            );
        }
    }

    desMarcarPromocode = async () => {
        try {
            const { showID, detalleRegistro, global } = this.props;
            const { promoCode } = this.state;

            const res = await Services.promocode.desMarcarPromocode({
                Codigo: promoCode,
                FuncionId: showID,
                DetalleRegistroId: detalleRegistro.Id,
            });
            if (res.status === 200) {
                this.setState({
                    promoCodeValidated: undefined,
                    promoCode: "",
                    promoCodeIsShown: true,
                });
                const amount = this.totalByTickets();
                this.props.updateAmountLuka(
                    amount + (this.state.donationSelected || 0)
                );
                const paymentMethods = global.paymentMethods.split(",");
                if (
                    paymentMethods.some((name) => name.toUpperCase() === "PAYU")
                )
                    this.props.updateAmountPayu();
            }
        } catch (error) {
            console.error(error);
        }
    };
    totalByTickets = () => {
        return this.props.reservationSeats.reduce((acc, curr) => {
            const fee = this.props.global.mostrarCargos
                ? this.props.conversion
                    ? currencyFormatValue(
                          this.props.global.dolarToday,
                          curr.Comision,
                          this.props.global.MostrarDecimales
                      )
                    : curr.Comision
                : 0;
            return (
                acc +
                (this.props.conversion
                    ? currencyFormatValue(
                          this.props.global.dolarToday,
                          curr.Precio,
                          this.props.global.MostrarDecimales
                      )
                    : curr.Precio) +
                fee
            );
        }, 0);
    };
    setDonationValue = async (donationAmount = 0) => {
        ReactPixel.init(this.props.global.facebookPixelId, {
            autoConfig: true,
            debug: true,
        });
        ReactPixel.pageView();
        ReactPixel.track("Donate");

        try {
            const { detalleRegistro, global } = this.props;
            const { donaciones, promoCodeValidated } = this.state;

            const resp = await Services.donaciones.setDonation(
                detalleRegistro.Id,
                {
                    FuncionOrganizacionDonacionId: donaciones[0].Id,
                    MontoDonacion: donationAmount,
                }
            );
            if (resp.status === 200) {
                const amount = this.totalByTickets();

                this.props.updateAmountLuka(
                    amount +
                        donationAmount -
                        (promoCodeValidated && promoCodeValidated.Valido
                            ? promoCodeValidated.MontoDescuento
                            : 0)
                );
                const paymentMethods = global.paymentMethods.split(",");
                if (
                    paymentMethods.some((name) => name.toUpperCase() === "PAYU")
                )
                    this.props.updateAmountPayu();
            }
        } catch (error) {
            console.error(error);
        }
    };

    componentDidMount() {
        const { showID, global } = this.props;
        this.calculateAmoutShop();
        Services.donaciones
            .getDonacionesById(showID, global.Idioma)
            .then((response) => {
                if (response.status === 200 && response.data.length > 0) {
                    this.setState({ donaciones: response.data });
                    this.setState({
                        donacionesMontos: response.data[0].Montos,
                    });

                    let id = 0;
                    var NewArrayMontos = [];
                    response.data[0].Montos.forEach((element) => {
                        id++;

                        NewArrayMontos.push({
                            id: id,
                            monto: this.props.conversion
                                ? currencyFormatValue(
                                      this.props.global.dolarToday,
                                      element,
                                      this.props.global.MostrarDecimales
                                  )
                                : element,
                            isSelected: false,
                        });
                    });

                    NewArrayMontos &&
                        this.setState({ donacionesNewMontos: NewArrayMontos });
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }

    calculateAmoutShop = () => {
        const { global, carProds, selectedFunction } = this.props;
        let montoT = 0;
        carProds.map((p) => {
            montoT =
                montoT + (p.precio * p.cantidad)
                // (selectedFunction.Iso === "VES"
                //     ? global.MonedaPorDefecto === "USD"
                //         ? currencyFormatValue(
                //               global.dolarToday,
                //               p.precio * p.cantidad,
                //               global.MostrarDecimales
                //           )
                //         : p.precio * p.cantidad
                //     : p.precio * p.cantidad);
        });
        this.setState({ MontoTienda: montoT });
    };

    totalMount = (monto, dolarToday, MostrarDecimales) => {
        if (dolarToday) {
            this.props.setTotalMount(
                currencyFormatValue(
                    dolarToday,
                    monto + this.state.MontoTienda,
                    MostrarDecimales
                )
            );
            return currencyFormatDE(
                currencyFormatValue(
                    dolarToday,
                    monto + this.state.MontoTienda,
                    MostrarDecimales
                ),
                this.props.global.thousandsSeparator,
                this.props.global.decimalSeparator,
                this.props.global.MostrarDecimales
            );
        } else {
            this.props.setTotalMount(monto + this.state.MontoTienda);
            return currencyFormatDE(
                monto + this.state.MontoTienda,
                this.props.global.thousandsSeparator,
                this.props.global.decimalSeparator,
                this.props.global.MostrarDecimales
            );
        }
    };

    calculateFee = (price, percentage, mostrarDecimales = true) =>
        mostrarDecimales
            ? (price * percentage) / 100
            : Math.floor((price * percentage) / 100);
    renderTooltip = (Simbolo) => {
        const { t } = this.props;
        return (
            <div className="tooltip-container">
                <div className="tooltip-header">
                    <span>{t("type").toUpperCase()}</span>
                    <span>{t("price").toUpperCase()}</span>
                    <span>{t("fee").toUpperCase()}</span>
                </div>

                {this.props.resumeTickets.length > 0
                    ? this.props.resumeTickets.map((t) => {
                          if (t.cantidadSel === 0) {
                              return null;
                          }
                          const fee = t.EsPorcentual
                              ? this.calculateFee(
                                    t.Precio,
                                    t.Valor,
                                    global.MostrarDecimales
                                )
                              : t.Valor;
                          return (
                              <div
                                  key={t.TipoEntradaNombre}
                                  className="tooltip-content"
                              >
                                  <span>{t.TipoEntradaNombre}</span>
                                  <span>
                                      {this.props.Iso === "VES"
                                          ? this.props.global
                                                .MonedaPorDefecto === "VES"
                                              ? `${Simbolo}
                                  ${currencyFormatDE(
                                      t.Precio * t.cantidadSel,
                                      this.props.global.thousandsSeparator,
                                      this.props.global.decimalSeparator,
                                      this.props.global.MostrarDecimales
                                  )}`
                                              : `$
                                    ${currencyFormatDE(
                                        currencyFormatValue(
                                            this.props.global.dolarToday,
                                            t.Precio * t.cantidadSel,
                                            this.props.global.MostrarDecimales
                                        ),
                                        this.props.global.thousandsSeparator,
                                        this.props.global.decimalSeparator,
                                        this.props.global.MostrarDecimales
                                    )}`
                                          : `${Simbolo}
                                  ${currencyFormatDE(
                                      t.Precio * t.cantidadSel,
                                      this.props.global.thousandsSeparator,
                                      this.props.global.decimalSeparator,
                                      this.props.global.MostrarDecimales
                                  )}`}
                                  </span>
                                  <span>
                                      {this.props.Iso === "VES"
                                          ? this.props.global
                                                .MonedaPorDefecto === "VES"
                                              ? `${Simbolo}
                                  ${currencyFormatDE(
                                      fee * t.cantidadSel,
                                      this.props.global.thousandsSeparator,
                                      this.props.global.decimalSeparator,
                                      this.props.global.MostrarDecimales
                                  )}`
                                              : `$
                                    ${currencyFormatDE(
                                        currencyFormatValue(
                                            this.props.global.dolarToday,
                                            fee * t.cantidadSel,
                                            this.props.global.MostrarDecimales
                                        ),
                                        this.props.global.thousandsSeparator,
                                        this.props.global.decimalSeparator,
                                        this.props.global.MostrarDecimales
                                    )}`
                                          : `${Simbolo}
                                  ${currencyFormatDE(
                                      fee * t.cantidadSel,
                                      this.props.global.thousandsSeparator,
                                      this.props.global.decimalSeparator,
                                      this.props.global.MostrarDecimales
                                  )}`}
                                  </span>
                              </div>
                          );
                      })
                    : null}
            </div>
        );
    };

    render() {
        const {
            resumeTickets,
            global,
            t,
            Simbolo,
            Iso,
            selectedFunction,
            totalMountPay,
            reservationSeats,
        } = this.props;
        const { promoCodeValidated, donaciones, isLoading } = this.state;
        let MontoIVA = 0;
        let Monto = 0;
        let TotalEnt = 0;
        let TotalImp = 0;
        resumeTickets.map((t) => {
            TotalEnt += t.cantidadSel;
        });
        return (
            <>
                <section className="resumen">
                    <div className="resume-pay-mobile">
                        <div className="title-g sub-title-bold color-titulos">
                            <span
                                data-toggle="modal"
                                data-target="#modalFailure"
                            >
                                {t("orderSummary")}
                            </span>
                        </div>
                        <div className="wrap-resumen con-total">
                            <div className="tickets show tickets-g">
                                <a href="#" className="ver-t clear-fl">
                                    <p className="color-titulos">
                                        {t("tickets")} <span>({TotalEnt})</span>
                                    </p>
                                    <p></p>
                                </a>
                                <div className="title clear-fl">
                                    <span> {t("type").toUpperCase()}</span>
                                    <span>{t("qtyAbr").toUpperCase()}</span>
                                    <span>{t("subTotal").toUpperCase()}</span>
                                </div>
                                <article>
                                    {resumeTickets.length > 0
                                        ? resumeTickets.map((t, i) => {
                                              let fee = t.EsPorcentual
                                                  ? this.calculateFee(
                                                        Iso === "VES" &&
                                                            global.MonedaPorDefecto ===
                                                                "USD"
                                                            ? currencyFormatValue(
                                                                  global.dolarToday,
                                                                  t.Precio,
                                                                  global.MostrarDecimales
                                                              )
                                                            : t.Precio,
                                                        t.Valor,
                                                        global.MostrarDecimales
                                                    )
                                                  : Iso === "VES" &&
                                                    global.MonedaPorDefecto ===
                                                        "USD"
                                                  ? currencyFormatValue(
                                                        global.dolarToday,
                                                        t.Valor,
                                                        global.MostrarDecimales
                                                    )
                                                  : t.Valor;
                                              if (global.mostrarCargos) {
                                                  // fee = parseFloat( currencyFormatDE(
                                                  //   fee,
                                                  //   global.thousandsSeparator,
                                                  //   global.decimalSeparator,
                                                  //   global.MostrarDecimales
                                                  // ));

                                                  Monto =
                                                      Monto +
                                                      (Iso === "VES" &&
                                                      global.MonedaPorDefecto ===
                                                          "USD"
                                                          ? currencyFormatValue(
                                                                global.dolarToday,
                                                                t.Precio,
                                                                global.MostrarDecimales
                                                            ) + fee
                                                          : t.Precio + fee) *
                                                          t.cantidadSel;
                                                  //   console.log(Monto, "montooo", fee, currencyFormatValue(
                                                  //     global.dolarToday,
                                                  //     t.Precio,
                                                  //     global.MostrarDecimales
                                                  // ),t.cantidadSel);
                                                  // Monto =
                                                  // Monto +
                                                  // currencyFormatDE(
                                                  //   ((Iso === 'VES' &&
                                                  //   global.MonedaPorDefecto === 'USD'
                                                  //     ? currencyFormatValue(
                                                  //         global.dolarToday,
                                                  //         t.Precio,
                                                  //         global.MostrarDecimales
                                                  //       )
                                                  //     : t.Precio) +
                                                  //     fee) *
                                                  //     t.cantidadSel,
                                                  //   global.thousandsSeparator,
                                                  //   global.decimalSeparator,
                                                  //   global.MostrarDecimales
                                                  // );
                                              } else {
                                                  Monto =
                                                      Monto +
                                                      (Iso === "VES"
                                                          ? global.MonedaPorDefecto ===
                                                            "USD"
                                                              ? currencyFormatValue(
                                                                    global.dolarToday,
                                                                    t.Precio,
                                                                    global.MostrarDecimales
                                                                ) + fee
                                                              : t.Precio + fee
                                                          : t.Precio + fee) *
                                                          t.cantidadSel;
                                              }

                                              if (t.cantidadSel >= 1) {
                                                  const fee = (t.EsPorcentual
                                                      ? this.calculateFee(
                                                            Iso === "VES" &&
                                                                global.MonedaPorDefecto ===
                                                                    "USD"
                                                                ? currencyFormatValue(
                                                                      global.dolarToday,
                                                                      t.Precio,
                                                                      global.MostrarDecimales
                                                                  )
                                                                : t.Precio,
                                                            t.Valor,
                                                            global.MostrarDecimales
                                                        )
                                                      : Iso === "VES" &&
                                                        global.MonedaPorDefecto ===
                                                            "USD"
                                                      ? currencyFormatValue(
                                                            global.dolarToday,
                                                            t.Valor,
                                                            global.MostrarDecimales
                                                        )
                                                      : t.Valor);

                                                  return (
                                                      <div
                                                          className="ticket clear-fl"
                                                          key={i}
                                                      >
                                                          <p>
                                                              <span></span>
                                                              <span>
                                                                  {
                                                                      t.TipoEntradaNombre
                                                                  }
                                                              </span>
                                                          </p>
                                                          <p>{t.cantidadSel}</p>
                                                          <p>
                                                            
                                                              {Iso === "VES"
                                                                  ? global.MonedaPorDefecto ===
                                                                    "VES"
                                                                      ? `${Simbolo} ${
                                                                            (global.mostrarCargos && this.props.selectedFunction.TipoDesglose !== 2 ) || (this.props.selectedFunction.TipoDesglose === 7 )
                                                                                ? currencyFormatDE(
                                                                                      (t.Precio +
                                                                                          (this.props.selectedFunction.TipoDesglose === 7 ? 0 : fee)) *
                                                                                          t.cantidadSel,
                                                                                      this
                                                                                          .props
                                                                                          .global
                                                                                          .thousandsSeparator,
                                                                                      this
                                                                                          .props
                                                                                          .global
                                                                                          .decimalSeparator,
                                                                                      this
                                                                                          .props
                                                                                          .global
                                                                                          .MostrarDecimales
                                                                                  )
                                                                                : currencyFormatDE(
                                                                                      (t.Precio +
                                                                                          t.Comision -
                                                                                          ((t.MontoIVA ||
                                                                                              0) +
                                                                                              (t.ComisionIVA ||
                                                                                                  0))) *
                                                                                          t.cantidadSel,
                                                                                      this
                                                                                          .props
                                                                                          .global
                                                                                          .thousandsSeparator,
                                                                                      this
                                                                                          .props
                                                                                          .global
                                                                                          .decimalSeparator,
                                                                                      this
                                                                                          .props
                                                                                          .global
                                                                                          .MostrarDecimales
                                                                                  )
                                                                        }`
                                                                      : `$ ${ (global.mostrarCargos && this.props.selectedFunction.TipoDesglose !== 2 ) || (this.props.selectedFunction.TipoDesglose === 7 )
                                                                                ? currencyFormatDE(
                                                                                      (currencyFormatValue(
                                                                                          global.dolarToday,
                                                                                          t.Precio,
                                                                                          global.MostrarDecimales
                                                                                      ) +
                                                                                            (this.props.selectedFunction.TipoDesglose === 7 ? 0 : fee)) *
                                                                                          t.cantidadSel,
                                                                                      this
                                                                                          .props
                                                                                          .global
                                                                                          .thousandsSeparator,
                                                                                      this
                                                                                          .props
                                                                                          .global
                                                                                          .decimalSeparator,
                                                                                      this
                                                                                          .props
                                                                                          .global
                                                                                          .MostrarDecimales
                                                                                  )
                                                                                : currencyFormatDE(
                                                                                      currencyFormatValue(
                                                                                          global.dolarToday,
                                                                                          (t.Precio +
                                                                                              t.Comision -
                                                                                              ((t.MontoIVA ||
                                                                                                  0) +
                                                                                                  (t.ComisionIVA ||
                                                                                                      0))) *
                                                                                              t.cantidadSel,
                                                                                          global.MostrarDecimales
                                                                                      ),
                                                                                      this
                                                                                          .props
                                                                                          .global
                                                                                          .thousandsSeparator,
                                                                                      this
                                                                                          .props
                                                                                          .global
                                                                                          .decimalSeparator,
                                                                                      this
                                                                                          .props
                                                                                          .global
                                                                                          .MostrarDecimales
                                                                                  )
                                                                        }`
                                                                  : `${Simbolo} ${
                                                                        global.mostrarCargos
                                                                            ? currencyFormatDE(
                                                                                  (t.Precio +
                                                                                      fee) *
                                                                                      t.cantidadSel,
                                                                                  this
                                                                                      .props
                                                                                      .global
                                                                                      .thousandsSeparator,
                                                                                  this
                                                                                      .props
                                                                                      .global
                                                                                      .decimalSeparator,
                                                                                  this
                                                                                      .props
                                                                                      .global
                                                                                      .MostrarDecimales
                                                                              )
                                                                            : currencyFormatDE(
                                                                                  (t.Precio +
                                                                                      fee) *
                                                                                      t.cantidadSel,
                                                                                  this
                                                                                      .props
                                                                                      .global
                                                                                      .thousandsSeparator,
                                                                                  this
                                                                                      .props
                                                                                      .global
                                                                                      .decimalSeparator,
                                                                                  this
                                                                                      .props
                                                                                      .global
                                                                                      .MostrarDecimales
                                                                              )
                                                                    }`}
                                                          </p>
                                                        <i
                                                              className="icon-cerrar entrada"
                                                              ata-index="0"
                                                              onClick={() => {
                                                                  this.setState(
                                                                      {
                                                                          liberarTicketsSelected: true,
                                                                          liberarTicketsSelectedFreeAssignation: false,
                                                                          liberarSelected:
                                                                              t,
                                                                      }
                                                                  );
                                                              }}
                                                          ></i>
                                                      </div>
                                                  );
                                              }
                                          })
                                        : null}
                                </article>
                            </div>
                            {this.props.carProds.length > 0 && (
                                <div
                                    className="car"
                                    style={{ padding: "0 26px 20px" }}
                                >
                                    <a href="#" className="ver-t clear-fl">
                                        <p className="color-titulos">
                                            <b>{t("Agregados")}</b>
                                        </p>
                                        <CarTop />
                                    </a>
                                </div>
                            )}
                            <div className="don">
                                {this.state.donationSelected && (
                                    <article className="clear-fl">
                                        <p className="clear-fl">
                                            <span> {t("donate")}</span>
                                            <span>
                                                {Simbolo}{" "}
                                                {currencyFormatDE(
                                                    this.state.donationSelected,
                                                    this.props.global
                                                        .thousandsSeparator,
                                                    this.props.global
                                                        .decimalSeparator,
                                                    this.props.global
                                                        .MostrarDecimales
                                                )}
                                            </span>
                                            <i
                                                className="icon-cerrar entrada"
                                                data-index="3"
                                                onClick={() => {
                                                    this.setState(
                                                        {
                                                            donationSelected:
                                                                undefined,
                                                            selectedDonationId: 0,
                                                            donationSelectedInput:
                                                                undefined,
                                                            donacionIsShown: false,
                                                            selectedDonationOtros: false,
                                                            donationInputToShow:
                                                                undefined,
                                                        },
                                                        () => {
                                                            this.setDonationValue();
                                                        }
                                                    );
                                                }}
                                            ></i>
                                        </p>
                                    </article>
                                )}
                            </div>
                            <div className="promo">
                                {promoCodeValidated &&
                                promoCodeValidated.Valido ? (
                                    <p
                                        className={
                                            "clear-fl " +
                                            (promoCodeValidated ? "show" : "")
                                        }
                                    >
                                        <span>{t("promoCode")}</span>
                                        <span>
                                            {`- ${Simbolo} `}
                                            {currencyFormatDE(
                                                promoCodeValidated.MontoDescuento,
                                                this.props.global
                                                    .thousandsSeparator,
                                                this.props.global
                                                    .decimalSeparator,
                                                this.props.global
                                                    .MostrarDecimales
                                            )}
                                        </span>
                                        <i
                                            className="icon-cerrar"
                                            data-index="2"
                                            onClick={() =>
                                                this.desMarcarPromocode()
                                            }
                                        ></i>
                                    </p>
                                ) : (
                                    <>
                                        <a
                                            className={
                                                "ver-input clear-fl color-ppal " +
                                                (this.state.promoCodeIsShown
                                                    ? "show"
                                                    : "")
                                            }
                                            onClick={() =>
                                                this.setState({
                                                    promoCodeIsShown:
                                                        !this.state
                                                            .promoCodeIsShown,
                                                })
                                            }
                                        >
                                            {t("havePromoCode")}
                                        </a>
                                        <article
                                            className={
                                                "clear-fl " +
                                                (!this.state.promoCodeIsShown
                                                    ? "show"
                                                    : "")
                                            }
                                        >
                                            <div>
                                                <div
                                                    className={`wrap-input ${
                                                        promoCodeValidated &&
                                                        !promoCodeValidated.Valido
                                                            ? "error"
                                                            : ""
                                                    }`}
                                                >
                                                    <input
                                                        type="text"
                                                        onChange={
                                                            this
                                                                .handleChangePromoCode
                                                        }
                                                        value={
                                                            this.state.promoCode
                                                        }
                                                    />
                                                    {promoCodeValidated &&
                                                    !promoCodeValidated.Valido ? (
                                                        <div className="errorText">
                                                            {t(
                                                                "invalidPromoCode"
                                                            )}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <button
                                                className={
                                                    "btn-light " +
                                                    (this.state.promoCode
                                                        ? ""
                                                        : "inactive")
                                                }
                                                onClick={() => {
                                                    if (this.state.promoCode) {
                                                        this.validarPromoCode();
                                                    }
                                                }}
                                            >
                                                {isLoading ? (
                                                    <div className="spinner-main spinner5">
                                                        <div className="loadingio-spinner-rolling-k94er4xvtw spinner5-b">
                                                            <div className="ldio-arie1i327iv spinner5-c">
                                                                <div></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    t("apply")
                                                )}
                                            </button>
                                            <i
                                                className="icon-cerrar"
                                                data-index="2"
                                                onClick={() => {
                                                    this.setState({
                                                        promoCodeValidated:
                                                            undefined,
                                                        promoCodeIsShown: true,
                                                    });
                                                }}
                                            ></i>
                                        </article>
                                    </>
                                )}
                            </div>


                            {this.props.selectedFunction.TipoDesglose === 2 && (
                                <div className="impuestos">
                                    <a href="#">
                                        <p className="color-titulos">
                                            <b>Impuestos</b>
                                        </p>
                                    </a>
                                    <div className="list">
                                        <p>
                                            <span>IVA 16%</span>
                                            <span>
                                                {resumeTickets.length > 0 &&
                                                    resumeTickets.map(
                                                        (t, idx) => {
                                                          
                                                            MontoIVA +=
                                                                (t.MontoIVA +
                                                                    t.ComisionIVA) *
                                                                t.cantidadSel;
                                                        }
                                                    )}
                                                {Iso === "VES"
                                                    ? global.MonedaPorDefecto ===
                                                      "USD"
                                                        ? global.MonedaPorDefecto
                                                        : Simbolo
                                                    : Simbolo}{" "}
                                                {Iso === "VES" &&
                                                global.MonedaPorDefecto ===
                                                    "VES"
                                                    ? MontoIVA.toFixed(2)
                                                    : (
                                                          MontoIVA /
                                                          global.dolarToday
                                                      ).toFixed(2)}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            )}

                            <div id="total" className="bg-secundario">
                                <p className="clear-fl">
                                    {t("totalOrder")}
                                    <span>
                                        {Iso === "VES"
                                            ? global.MonedaPorDefecto === "USD"
                                                ? global.MonedaPorDefecto
                                                : Simbolo
                                            : Simbolo}{" "}
                                        {
                                            
                                            `${
                                                this.props.selectedFunction
                                                    .TipoDesglose === 2
                                                    ? this.totalMount(
                                                          Monto,
                                                          (promoCodeValidated
                                                              ? promoCodeValidated.MontoDescuento
                                                              : 0) +
                                                              (parseFloat(
                                                                  this.state
                                                                      .donationSelected
                                                              ) || 0)
                                                      )
                                                    : this.totalMount(
                                                          Monto +
                                                              TotalImp -
                                                              MontoIVA -
                                                              ((promoCodeValidated && promoCodeValidated.MontoDescuento) ? promoCodeValidated.MontoDescuento: 0) +
                                                              (parseFloat(
                                                                  this.state
                                                                      .donationSelected
                                                              ) || 0)
                                                      )
                                            }`
                                        }
                                    </span>
                                </p>
                                {global.mostrarCargos &&
                                    this.props.selectedFunction.TipoDesglose !==
                                        2 && (
                                        <Fragment>
                                            <span className="fee-included">
                                                <label data-tip data-for="fees">
                                                    {t("feesIncluded")}
                                                </label>
                                                <div className="tooltip-fee">
                                                    {this.renderTooltip(
                                                        Simbolo
                                                    )}
                                                </div>
                                            </span>
                                        </Fragment>
                                    )}
                            </div>
                        </div>
                        {this.props.carProds.length > 0 && (
                            <div className="notaProd">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="30"
                                    height="30"
                                    viewBox="0 0 41 40"
                                >
                                    <defs>
                                        <clipPath id="clip-Artboard_1">
                                            <rect width="35" height="35"></rect>
                                        </clipPath>
                                    </defs>
                                    <g
                                        id="Artboard_1"
                                        data-name="Artboard – 1"
                                        clipPath="url(#clip-Artboard_1)"
                                    >
                                        <g
                                            id="Group_3202"
                                            data-name="Group 3202"
                                            transform="translate(-1121.989 -119.583)"
                                        >
                                            <path
                                                id="Path_3516"
                                                data-name="Path 3516"
                                                d="M161.623,258.641a12.755,12.755,0,0,0-5.6-2.132,13,13,0,0,0-1.585-.1h0a12.739,12.739,0,0,0-11.823,8.033,13.379,13.379,0,0,0-.509,1.588,12.735,12.735,0,0,0-.382,3.1c0,.21.006.42.016.627a12.39,12.39,0,0,0,.178,1.585,12.66,12.66,0,0,0,2.718,5.881,13.21,13.21,0,0,0,1.6,1.617,12.7,12.7,0,0,0,8.2,3.007c.4,0,.8-.019,1.187-.057a12.713,12.713,0,0,0,6-23.146Zm-7.189,21.612a11.108,11.108,0,0,1-8.2-3.618,11.26,11.26,0,0,1-1.6-2.256,11.047,11.047,0,0,1-1.1-3.039,10.836,10.836,0,0,1-.2-1.585c-.013-.207-.019-.417-.019-.627a11.048,11.048,0,0,1,.439-3.1,11.308,11.308,0,0,1,.6-1.588A11.144,11.144,0,0,1,154.434,258h0a11.322,11.322,0,0,1,1.585.111,11.127,11.127,0,0,1-1.588,22.141Z"
                                                transform="translate(988.053 -129.545)"
                                                fill="var(--c1)"
                                            ></path>
                                            <path
                                                id="Path_3517"
                                                data-name="Path 3517"
                                                d="M179.97,279.995a.75.75,0,0,1-.8-.7v-9.267a.8.8,0,0,1,1.591,0V279.3A.75.75,0,0,1,179.97,279.995Z"
                                                transform="translate(962.519 -138.357)"
                                                fill="var(--c1)"
                                            ></path>
                                            <path
                                                id="Path_3518"
                                                data-name="Path 3518"
                                                d="M179.97,310.38a.8.8,0,0,1-.8-.8v-1.731a.8.8,0,0,1,1.591,0v1.731A.8.8,0,0,1,179.97,310.38Z"
                                                transform="translate(962.519 -164.073)"
                                                fill="var(--c1)"
                                            ></path>
                                        </g>
                                    </g>
                                </svg>
                                <span>
                                    Los Productos serán entregados el dia del
                                    evento en las instalaciones.
                                </span>
                            </div>
                        )}
                    </div>
                    {/* <!--span className="nota-fees">Todos los <b>Cargos</b> estan incluidos</span--> */}
                    {this.state.donaciones.length >= 1 && (
                        <div className="donaciones">
                            {global.BannerDonacionPequeno ? (
                                <figure>
                                    <img
                                        src={global.BannerDonacionPequeno}
                                        alt="Banner donación"
                                    />
                                </figure>
                            ) : (
                                <figure>
                                    <img
                                        src="./../../images/pago/donacion.jpg"
                                        alt=""
                                    />
                                </figure>
                            )}
                            <div
                                className={
                                    "btns multiple clear-fl " +
                                    (this.state.donacionIsShown
                                        ? "show-input"
                                        : "")
                                }
                            >
                                <p>
                                    {t("labelConsiderDonation")}

                                    <span>
                                        <b>
                                            {donaciones.length > 0
                                                ? donaciones[0].Nombre
                                                : ""}
                                        </b>
                                        <span
                                            className="donacion"
                                            data-toggle="modal"
                                            onClick={() =>
                                                this.setState({
                                                    donacionesLearnMore:
                                                        !this.state
                                                            .donacionesLearnMore,
                                                })
                                            }
                                            data-target="#modalMoreDon"
                                        >
                                            {t("labelReadMore")}
                                        </span>
                                    </span>
                                </p>
                                <div>
                                    {this.state.donacionesNewMontos &&
                                        this.state.donacionesNewMontos.map(
                                            (m, index) => (
                                                <a
                                                    key={index}
                                                    className={
                                                        m.id ==
                                                        this.state
                                                            .selectedDonationId
                                                            ? "active"
                                                            : ""
                                                    }
                                                    onClick={() => {
                                                        this.setState({
                                                            donationSelected:
                                                                m.monto,
                                                        });
                                                        this.setDonationValue(
                                                            m.monto
                                                        );
                                                        if (
                                                            this.state
                                                                .selectedDonationId !==
                                                            m.id
                                                        ) {
                                                            this.setState({
                                                                selectedDonationId:
                                                                    m.id,
                                                            });
                                                            this.setState({
                                                                selectedDonationOtros: false,
                                                            });
                                                        } else {
                                                            this.setState({
                                                                selectedDonationId:
                                                                    undefined,
                                                            });
                                                            this.setState({
                                                                donationSelected:
                                                                    undefined,
                                                            });
                                                            this.setDonationValue();
                                                        }
                                                    }}
                                                >
                                                    {Simbolo}
                                                    {currencyFormatDE(
                                                        m.monto,
                                                        this.props.global
                                                            .thousandsSeparator,
                                                        this.props.global
                                                            .decimalSeparator,
                                                        this.props.global
                                                            .MostrarDecimales
                                                    )}
                                                </a>
                                            )
                                        )}
                                    <a
                                        id="otrosDon"
                                        className={
                                            this.state.selectedDonationOtros
                                                ? "active"
                                                : ""
                                        }
                                        onClick={() => {
                                            this.setState({
                                                donacionIsShown:
                                                    !this.state.donacionIsShown,
                                            });
                                            this.setState({
                                                selectedDonationOtros: true,
                                            });
                                        }}
                                    >
                                        {t("labelOthers")}
                                    </a>
                                </div>
                                <div>
                                    <div className="wrap-input">
                                        <CurrencyInput
                                            placeholder={`${Simbolo} 0${global.decimalSeparator}00`}
                                            type="text"
                                            newmask={{
                                                thousandsSeparatorSymbol:
                                                    global.thousandsSeparator,
                                                allowDecimal:
                                                    global.decimalSeparator,
                                                decimalSymbol:
                                                    global.decimalSeparator,
                                            }}
                                            onChange={(e) => {
                                                this.setState({
                                                    ...this.state,
                                                    donationInputToShow:
                                                        e.target.value,
                                                    donationSelectedInput:
                                                        currencyFormatDonation(
                                                            e.target.value,
                                                            global.thousandsSeparator,
                                                            global.decimalSeparator,
                                                            global.MostrarDecimales
                                                        ),
                                                });
                                            }}
                                            value={
                                                this.state
                                                    .donationInputToShow || ""
                                            }
                                        />
                                    </div>
                                    <button
                                        id="btn-donar-ot"
                                        className={
                                            "btn-light " +
                                            (this.state.donationSelectedInput
                                                ? ""
                                                : "inactive")
                                        }
                                        onClick={() => {
                                            if (
                                                this.state.donationSelectedInput
                                            ) {
                                                const customAmount =
                                                    this.state
                                                        .donationSelectedInput;
                                                this.setState(
                                                    {
                                                        donationSelected:
                                                            customAmount,
                                                        donationSelectedInput:
                                                            undefined,
                                                        donacionIsShown:
                                                            !this.state
                                                                .donacionIsShown,
                                                        selectedDonationId: 0,
                                                    },
                                                    () => {
                                                        this.setDonationValue(
                                                            customAmount
                                                        );
                                                    }
                                                );
                                            }
                                        }}
                                    >
                                        {t("donate")}
                                    </button>
                                    <i
                                        className="icon-cerrar"
                                        onClick={() => {
                                            this.setState(
                                                {
                                                    donacionIsShown:
                                                        !this.state
                                                            .donacionIsShown,
                                                    donationSelectedInput:
                                                        undefined,
                                                    selectedDonationOtros: false,
                                                    donationSelected: undefined,
                                                    donationInputToShow:
                                                        undefined,
                                                },
                                                () => {
                                                    this.setDonationValue();
                                                }
                                            );
                                        }}
                                    ></i>
                                </div>
                            </div>
                        </div>
                    )}
                </section>
                <div
                    className={
                        this.state.donacionesLearnMore
                            ? "modal fade in"
                            : "modal fade"
                    }
                    style={{
                        display: this.state.donacionesLearnMore
                            ? "block"
                            : "none",
                    }}
                    id="modalMoreDon"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="modalMoreDon"
                    aria-hidden="true"
                >
                    <div
                        className="modal-dialog modal-dialog-centered modal-lg"
                        role="document"
                    >
                        <div className="modal-content">
                            {global.BannerDonacionGrande ? (
                                <img
                                    src={global.BannerDonacionGrande}
                                    alt="Banner donación"
                                />
                            ) : (
                                <img
                                    src="./../../images/pago/learDon.jpg"
                                    alt=""
                                />
                            )}

                            <div className="conten-don clear-fl">
                                <div className="title sub-title-bold color-titulos">
                                    {donaciones &&
                                        donaciones.map((t) => t.Nombre)}
                                </div>
                                <div className="wrap-terms">
                                    <div className="text color-texto-contenido">
                                        {donaciones &&
                                            donaciones.map(
                                                (t) => t.Descripcion
                                            )}
                                    </div>
                                </div>
                                <a
                                    onClick={() =>
                                        this.setState({
                                            donacionesLearnMore:
                                                !this.state.donacionesLearnMore,
                                        })
                                    }
                                    className="btn-skin btn-modal-term btn-ppal active"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    {t("agree")}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className={
                        this.state.liberarTicketsSelected
                            ? "modal fade modalAlert in"
                            : "modal fade modalAlert"
                    }
                    style={{
                        display: this.state.liberarTicketsSelected
                            ? "block"
                            : "none",
                    }}
                    id="modalConfirm"
                    role="dialog"
                >
                    <div
                        className="modal-dialog modal-dialog-centered modal-md"
                        role="document"
                    >
                        <div className="modal-content">
                            <div className="conten-expired clear-fl">
                                <div className="top">
                                    <i className="icon-alert color-b"></i>
                                </div>
                                <div className="content">
                                    <div className="title color-titulos">
                                        {t("labelReleaseTickets")}
                                    </div>
                                    <p className="color-texto-contenido">
                                        {t("labelReleaseTicketsWarning")}
                                    </p>
                                    <a
                                        onClick={async () => {
                                            this.setState({
                                                liberarTicketsSelected:
                                                    !this.state
                                                        .liberarTicketsSelected,
                                            });
                                            const { reservationSeats } =
                                                this.props;
                                            const {
                                                liberarTicketsSelectedFreeAssignation,
                                                liberarSelected,
                                            } = this.state;
                                            try {
                                                let cancelReservations;
                                                if (
                                                    liberarTicketsSelectedFreeAssignation
                                                ) {
                                                    let entrada =
                                                        liberarSelected
                                                            .seccionTipoentrada[0]
                                                            .tipoEntrada[0];
                                                    cancelReservations =
                                                        reservationSeats.filter(
                                                            (rs) =>
                                                                rs.TipoEntradaId ===
                                                                entrada.Id
                                                        );
                                                } else {
                                                    cancelReservations =
                                                        reservationSeats.filter(
                                                            (rs) =>
                                                                rs.TipoEntradaId ===
                                                                liberarSelected.TipoEntradaId
                                                        );
                                                }

                                                const res = await Promise.all(
                                                    cancelReservations.map(
                                                        (item) => {
                                                            return Services.reservations.cancelReservation(
                                                                item.Id
                                                            );
                                                        }
                                                    )
                                                );
                                                res.forEach((r, index) => {
                                                    if (r.status === 200) {
                                                        this.props.deleteReservation(
                                                            cancelReservations[
                                                                index
                                                            ]
                                                        );
                                                    }
                                                });
                                                if (
                                                    liberarTicketsSelectedFreeAssignation
                                                ) {
                                                    this.props.setResumeTickets(
                                                        resumeTickets.filter(
                                                            (r) =>
                                                                r.ID !==
                                                                liberarSelected.ID
                                                        )
                                                    );
                                                } else {
                                                    this.props.setResumeTickets(
                                                        resumeTickets.filter(
                                                            (r) =>
                                                                r.TipoEntradaId !==
                                                                liberarSelected.TipoEntradaId
                                                        )
                                                    );
                                                }
                                                const paymentMethods =
                                                    global.paymentMethods.split(
                                                        ","
                                                    );
                                                if (
                                                    paymentMethods.some(
                                                        (name) =>
                                                            name.toUpperCase() ===
                                                            "PAYU"
                                                    )
                                                ) {
                                                    this.props.updateAmountPayu();
                                                }
                                                console.log(
                                                    "LUKA UPDATE",
                                                    this.props.totalMountPay
                                                );
                                                this.props.updateAmountLuka(
                                                    this.props.totalMountPay
                                                );
                                            } catch (err) {
                                                console.error(err);
                                            }
                                        }}
                                        id="conf-del"
                                        className="color-ppal hover-text-ppal"
                                    >
                                        {t("labelUnderstand").toUpperCase()}
                                    </a>
                                    <a
                                        onClick={() =>
                                            this.setState({
                                                liberarTicketsSelected:
                                                    !this.state
                                                        .liberarTicketsSelected,
                                            })
                                        }
                                        data-dismiss="modal"
                                        aria-label="Close"
                                        className="hover-content color-texto-contenido"
                                    >
                                        {t("labelBack").toUpperCase()}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default withTranslation()(ResumePay);
