import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Footer from './Footer';

const mapStateToProps = state => ({
  global: state.global
});

const mapDispatchToProps = dispatch => ({

});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Footer));