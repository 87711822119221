import React from 'react';

const ModalDialog = (props) => {
  const { display, setDisplay, title, content, extra, buttonText } = props;
  return (
    <div
      className={display ? 'modal fade modalAlert in' : 'modal fade modalAlert'}
      style={{ display: display ? 'block' : 'none' }}
      id="modalFailure"
      role="dialog"
    >
      <div
        className="modal-dialog modal-dialog-centered modal-md"
        role="document"
      >
        <div className="modal-content">
          <div className="conten-expired clear-fl">
            <div className="top">
              <i className="icon-cerrar color-b"></i>
            </div>
            <div className="content">
              <div className="title color-titulos">{title}</div>
              <p className="color-texto-contenido">{content}</p>

              <p className="color-texto-contenido">{extra}</p>
              <a
                href=""
                onClick={(e) => {
                  e.preventDefault();
                  props.handleClick();
                }}
                data-dismiss="modal"
                aria-label="Close"
                className="color-ppal hover-text-ppal"
              >
                {buttonText}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalDialog;
