import http, { getAll } from './http';

export const getComercios = async () => {
    let resp = await http.get(`/branches/`);
    return resp;
};
export const getDetalleComercio = async (branchId) => {
    let resp = await getAll([
        http.get(`/branches/${branchId}`),
        http.get(`/branches/${branchId}/work-schedules`)
    ]);
    let data = { ...resp[0].data, horarios: resp[1].data.data };
    return data;
};

export const getCategoriasPorComercio = async (branchId) => {
    let resp = await http.get(`/branches/${branchId}/categories/all`);
    return resp;
};
export const getCategoriasPorComercioConProductos = async (branchId,moneda) => {
    http.defaults.headers["X-Currency"]=moneda
    let resp = await http.get(
        `/branches/${branchId}/categories/all?with=goods`
    );
    return resp;
};

export const getCategoriaPorId = async (idCat, moneda) => {
    http.defaults.headers["X-Currency"]=moneda
    let resp = await http.get(`/categories/${idCat}`);
    return resp;
};

export const getProductosPorCatLimit = async (idCat, branchId, moneda) => {
    http.defaults.headers["X-Currency"]=moneda
    let resp = await http.get(
        `/branches/${branchId}/branch-goods?category_id=${idCat}&with=branch_good_properties`
    );
    return resp;
};
export const getProductosPorCat = async (idCat, branchId, moneda) => {
    http.defaults.headers["X-Currency"]=moneda
    let resp = await getAll([
        await http.get(`/categories/${idCat}`),
        await http.get(
            `/branches/${branchId}/branch-goods?category_id=${idCat}&with=branch_good_properties`
        )
    ]);
    return resp;
};
export const getProductosPorQuery = async (q, branchId, moneda) => {
    http.defaults.headers["X-Currency"]=moneda
    let resp = await http.get(
        `/branches/${branchId}/branch-goods/search?q=${q}`
    );
    return resp;
};
