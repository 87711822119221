import axios from "axios";
import { URL_API } from "../utils/globals";

// Default instace for axios with API path and timeout
const axiosInstanceAPI = axios.create({
    baseURL: URL_API,
    headers: { "content-type": "application/json" },
    mode: "cors",
});

export default {
    config: {
        getConfig: (domain) =>
            axiosInstanceAPI.get(`configuracion/ObtenerConfigSkin/${domain}`),
        getDetailSkin: (eventId) =>
            axiosInstanceAPI.get(`/eventoskin/${eventId}/es`),
        getEventsByFunctions: (eventId) =>
            axiosInstanceAPI.get(`FuncionSkin/${eventId}/es`),
    },
    external: {
        fetchDolar: () =>
            axios.get("https://ratedolar.blob.core.windows.net/dolar/data-rate.json")/*,

            axios.get("https://s3.amazonaws.com/dolartoday/data.json"),*/
    },
    reservations: {
        getByZoneSectionShow: (funcionid, puestoid) =>
            axiosInstanceAPI.get(
                `tipodeentrada/porpuesto/${funcionid}/${puestoid}`
            ),
        createReservation: (request) =>
            axiosInstanceAPI.post("/Reservacion/", request),
        createReservationV2: (request) =>
            axiosInstanceAPI.post("/reservacion/v2", request),
        cancelReservation: (id) => axiosInstanceAPI.delete(`Reservacion/${id}`),
        createReservationFreeAssignment: (id, request, token) => {
            axiosInstanceAPI.defaults.headers[
                "Authorization"
            ] = `Bearer ${token}`;
            return axiosInstanceAPI.post(
                `/reservacion/libreasignacion/${id}`,
                request
            );
        },
    },
    shows: {
        getShowById: (funcionid, Idioma) =>
            axiosInstanceAPI.get(`funcionskin/detalle/${funcionid}/${Idioma}`),
        notifyMe: (data) =>
            axiosInstanceAPI.post(`consumidor/ProximosEventos`, data),
    },
    donaciones: {
        getDonacionesById: (Id, Idioma) =>
            axiosInstanceAPI.get(`/donacion/organizaciones/${Id}/${Idioma}`),
        setDonation: (id, request) =>
            axiosInstanceAPI.post(`/detalleregistro/donacion/${id}`, request),
        getOrganizacionById: (Id, Idioma) =>
            axiosInstanceAPI.get(`donacion/organizacion/${Id}/${Idioma}`),
    },
    promocode: {
        valPromoCode: (request) =>
            axiosInstanceAPI.post("/Promocion/", request),
        desMarcarPromocode: (request) =>
            axiosInstanceAPI.post(`/promocion/desmarcar`, request),
    },
    tickets: {
        getFreeAssignmentTickets: (showID) =>
            axiosInstanceAPI.get(`/tipodeentrada/web/${showID}`),
        getMapSvgImage: (eventId) =>
            axiosInstanceAPI.get(
                `https://cdn.ticketmundo.live/ztest-mapa/${eventId}.xml`
            ),
    },
    pay: {
        initRegisterDetail: (data) =>
            axiosInstanceAPI.post(`detalleregistro`, data),
        initRegisterDetailMultiple: (data) =>
            axiosInstanceAPI.post(`reservacion/multi_funciones`, data),
        initRegisterDetailSimple: (data) =>
            axiosInstanceAPI.post(
                `detalleregistro/AgregarDetalleRegistroSencillo`,
                data
            ),
        updateEmailRegisterDetail: (id, data) =>
            axiosInstanceAPI.patch(`detalleregistro/${id}`, data),
        updateRegisterDetail: (id, data) =>
            axiosInstanceAPI.post(`detalleregistro/${id}/update`, data),
        getBanksKhipu: () => axiosInstanceAPI.get(`/Khipu/Bancos`),
        initPayKhipu: (data) => axiosInstanceAPI.post(`/Khipu/Iniciar`, data),
        verificarPagoKhipu: (data) =>
            axiosInstanceAPI.get(`/Khipu/Verificar/${data}`),
        initPayLuka: (idcomercio, idpais) =>
            axiosInstanceAPI.get(
                `configuracion/tokenluka/${idcomercio}/${idpais}`
            ),
        ProcessPayLuka: (data) =>
            axiosInstanceAPI.post(`transaccion`, data).catch((err) => {
                return err.response;
            }),
        ProcessPayLukaMultiple: (data) =>
            axiosInstanceAPI.post(`transaccion/multi_funciones`, data).catch((err) => {
                return err.response;
            }),
        ProcessPayLukaWithMerch: (data) =>
            axiosInstanceAPI
                .post(`transaccion/with_merch`, data)
                .catch((err) => {

                    return err.response;
                }),
        CreateOrderDD: (data) => {
            return axiosInstanceAPI
                .post(`services/dondemand/create_order`, data)
                .catch((err) => {

                    return err.response;
                });
        },
        singleProcessPayLuka: (data) =>
            axiosInstanceAPI.post(`transaccion/sencilla`, data),
        obtenerConfirmacion: (id) => axiosInstanceAPI.get(`confirmacion/${id}`),
        obtenerConfirmacionSencilla: (id) =>
            axiosInstanceAPI.get(`confirmacion/sencilla/${id}`),
    },
    share: {
        getShortenUrl: (url) =>
            axiosInstanceAPI.post(`Configuracion/AcortarUrl`, { url: url }),
    },
    eticket: {
        send: (tid, idioma) =>
            axiosInstanceAPI.get(`Confirmacion/EnviarEticket/${tid}/${idioma}`),
    },
    waiting: {
        check: (host) =>
            axiosInstanceAPI.get(
                `https://throttle.ticketmundo.live/api/throttle?host=${host}&perform=1`
            ),
    },
};
