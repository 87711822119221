import React from "react";
import { withTranslation } from "react-i18next";
import ReactPixel from "react-facebook-pixel";
import "./Purchase.css";
import ResumePay from "./ResumePay";
import FormPay from "./FormPay";
import PayMethods from "./PayMethods";
import Services from "../../../services/Services";
import GoTop from "../../../components/GoTop";
import { currencyFormatValue } from "../../../utils/globals";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

import {
  createCookie,
  emailRegex,
  URL_BASE_2,
  currencyFormatDE,
  urlEvent,
} from "../../../utils/globals";
import { NewBack } from "../../../utils/iconsSvg";
class Purchase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsShown: false,
      paymentFailure: false,
      detalleRegistro: null,
      initPay: null,
      selectedPay: "",
      formValid: false,
      startedPay: false,
      totalMount: 0,
      checkTerms: false,
      errorTerms: false,
      updatePayu: false,
      errorMessage: "",
      errorLuka: false,
      loading: false,
      formData: {
        firstName: "",
        lastName: "",
        email: "",
        repeatEmail: "",
        phoneNumber: "",
        region: "",
        address: "",
        city: "",
        zipCode: "",
      },
      formErrors: {
        firstName: "",
        lastName: "",
        email: "",
        repeatEmail: "",
        phoneNumber: "",
        region: "",
        address: "",
        city: "",
        zipCode: "",
      },
      bank: "",
      tokenLuka: {
        token: "",
        id: "",
      },
      email: "",
      consumidorID: 0,
      validCounter: 0,
      isValid: false,
      chile: 19,
    };
  }
  myRef = React.createRef();
  captchaRef = React.createRef();
  async componentDidMount() {
    console.log(this.props.carProds);
    const { tokenLuka, selectedPay } = this.state;
    ReactPixel.pageView();
    ReactPixel.track("Purchase");
    try {
      const { showID, reservationSeats, global, selectedFunction, totalMount } =
        this.props;
      let Monto = 0;
      let MontoComision = 0;
      reservationSeats.forEach((element) => {
        Monto +=
          selectedFunction.Iso === "VES"
            ? global.MonedaPorDefecto === "USD"
              ? currencyFormatValue(
                  global.dolarToday,
                  element.Precio,
                  global.MostrarDecimales
                )
              : element.Precio
            : element.Precio;

        MontoComision +=
          selectedFunction.Iso === "VES"
            ? global.MonedaPorDefecto === "USD"
              ? currencyFormatValue(
                  global.dolarToday,
                  element.Comision,
                  global.MostrarDecimales
                )
              : element.Comision
            : element.Comision;
      });
      this.setState({ selectedPay: "LUKA" });
      console.log(reservationSeats);
      const tickets = reservationSeats.map((e) => e.Id).join(",");
      // if (selectedFunction.PaisId !== this.state.chile) {
      //     this.setState({ selectedPay: "LUKA" });
      // } else {
      //     this.setState({ selectedPay: "WEBPAY_TRANSBANK" });
      // }

      // console.log({tickets: tickets,
      //   canalid: Number(global.Canalid),
      //   funcionid: showID,
      //   MontoUsd:
      //     selectedFunction.Iso === 'VES'
      //       ? global.MonedaPorDefecto === 'USD'
      //         ? Monto
      //         : 0
      //       : 0,
      //   MontoComisionUsd:MontoComision,
      //   MontoConversion:
      //     selectedFunction.Iso === 'VES'
      //       ? global.MonedaPorDefecto === 'USD'
      //         ? global.dolarToday
      //         : 0
      //       : 0,
      // })

      const { status, data } = await Services.pay.initRegisterDetail({
        tickets: tickets,
        canalid: Number(global.Canalid),
        funcionid: showID,
        MontoUsd:
          selectedFunction.Iso === "VES"
            ? global.MonedaPorDefecto === "USD"
              ? Monto
              : 0
            : 0,
        MontoComisionUsd: MontoComision,
        MontoConversion:
          selectedFunction.Iso === "VES"
            ? global.MonedaPorDefecto === "USD"
              ? global.dolarToday
              : 0
            : 0,
      });
      if (status === 200) {
        if (data) {
          this.setState({
            ...this.state,
            detalleRegistro: data,
            checkTerms: !global.CheckTeminosCondiciones,
          });
        }
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  }

  validateFormPay = async () => {
    this.updateValidCounter();

    let resp = this.state.formValid;
    if (this.state.formValid) {
      resp = await this.updateEmailRegisterDetail();
      console.log(resp);
      if (!resp) {
        Swal.fire({
          customClass: {
            title: "titleCode",
          },
          title: `Error al validar tu email, intente de nuevo`,
        });
      } else {
        if (resp.status && resp.status === 409) {
          resp = false;
          Swal.fire({
            customClass: {
              title: "titleCode",
            },
            title: `Los sentimos , has superado el número de tickets por usuario.`,
          });
        }
        if (resp.status && resp.status === 400) {
          resp = false;
          Swal.fire({
            customClass: {
              title: "titleCode",
            },
            title: `Usuario Bloqueado , por favor contacte a atencion al cliente.`,
          });
        }
        if (resp.status && resp.status === "reserva_pendiente") {
          Swal.fire({
            customClass: {
              title: "titleCode",
            },
            title: `Lo sentimos , El usuario tiene otra reserva pendiente.`,
            text: `Para continuar con una nueva compra, es necesario que complete el pago de su reserva actual.`,
          });
        }
      }
    }
    if (resp && this.props.carProds.length > 0) {
      const body = {
        client_email: this.state.email,
        client_name: `${document.getElementById("firstName")?.value} ${
          document.getElementById("lastName")?.value
        }`,
        function_id: this.props.selectedFunction.FuncionId.toString(),
        goods: this.props.carProds.map((p) => {
          console.log(p);
          return {
            good_id: p.good_id,
            quantity: p.cantidad,
            properties: p.variantes.map((v) => {
              return {
                property_id: v.op.property_id,
                value: v.value.id,
              };
            }),
          };
        }),
      };
      const { status, data } = await Services.pay.CreateOrderDD(body);
      if (status === 200 && !data.has_error) {
        const order_id = data.service_payload.order.id;
        const input = document.createElement("input");
        input.type = "hidden";
        input.id = "order_id";
        input.value = order_id;
        document.body.append(input);
      } else {
        resp = false;
        Swal.fire({
          customClass: {
            title: "titleCode",
          },
          title: `Los sentimos , ocurrió un error al crear su orden de compra`,
        });
      }
    }
    return resp;
  };
  validateTerms = () => {
    const { global } = this.props;
    this.setState({
      errorTerms: global.CheckTeminosCondiciones && !this.state.checkTerms,
    });
  };
  updateAmountPayu = () => {
    this.setState({ updatePayu: !this.state.updatePayu });
  };
  updateAmountLuka = async (monto) => {
    const { tokenLuka, selectedPay } = this.state;
    window.luka.updateMonto(monto, tokenLuka.token);
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      ...this.state,
      formData: { ...this.state.formData, [name]: value },
    });
  };

  updateValidCounter = () => {
    this.setState({
      validCounter: this.state.validCounter + 1,
    });
  };

  updateEmailRegisterDetail = async () => {
    const { detalleRegistro, selectedPay, tokenLuka, email, formData } =
      this.state;
    const body = [
      {
        op: "replace",
        path: "/Email",
        value: email,
      },
      {
        op: "replace",
        path: "/Telefono",
        value: document.getElementById("phoneNumber")?.value,
      },
      {
        op: "replace",
        path: "/Ciudad",
        value: document.getElementById("city")?.value,
      },
      {
        op: "replace",
        path: "/Region",
        value: document.getElementById("region")?.value,
      },
      {
        op: "replace",
        path: "/ZipCode",
        value: document.getElementById("zipCode")?.value,
      },
      {
        op: "replace",
        path: "/Direccion",
        value: document.getElementById("address")?.value,
      },
    ];
    console.log("anteeeeessssss");
    try {
      console.log("anteeeeessssss resp 1");

      const resp = await Services.pay.updateEmailRegisterDetail(
        detalleRegistro.Id,
        body
      );

      console.log(resp, "******resp *");
      const resp2 = await Services.pay.updateRegisterDetail(
        detalleRegistro.Id,
        {
          Email: document.getElementById("email").value,
          Nombre: document.getElementById("firstName")?.value,
          Apellido: document.getElementById("lastName")?.value,
          Telefono: document.getElementById("phoneNumber")?.value,
          Ciudad: document.getElementById("city")?.value,
          Region: document.getElementById("region")?.value,
          ZipCode: document.getElementById("zipcode")?.value,
          Direccion: document.getElementById("address")?.value,
          Referencia: document.getElementById("referencia")?.value,
          Dni:
            (document.getElementById("nacionalidad")
              ? document.getElementById("nacionalidad").value
              : "") +
            (document.getElementById("cedula")
              ? document.getElementById("cedula").value
              : ""),
        }
      );

      if (this.props.isGratis) {
        console.log(resp2, "return por evento gratis");
        return true;
      }
      if (resp2.data.reserva_pendiente === false) {
        if (resp && resp.status === 200) {
          //this.setState({ consumidorID: data.ConsumidorID });
          //if (selectedPay === "LUKA" && tokenLuka.token !== "")
          window.luka.updateEmail(email, tokenLuka.token);
          let nombrePagador = `${
            document.getElementById("firstName").value || " "
          } ${document.getElementById("lastName").value || " "}`;
          window.luka.updateNombrePagador(nombrePagador, tokenLuka.token);
          return true;
        } else {
          console.log(resp);
          return false;
        }
      } else {
        return { data: resp2.data, status: "reserva_pendiente" };
      }
    } catch (error) {
      console.log("error 409");

      if (error.response && error.response.status === 409) {
        return { data: error.response.data[0], status: 409 };
      }
      if (error.response && error.response.status === 400) {
        return { data: error.response.data[0], status: 400 };
      }
      return false;
    }
  };

  handleErrorMessage = (m) => {
    this.setState({ ...this.state, errorMessage: m });
  };
  handleErrorLuka = (v, m) => {
    this.setState({
      ...this.state,
      errorLuka: v,
      errorMessage: m,
      paymentFailure: true,
    });
  };
  handleSelectPay = (selectedPay) => {
    this.setState({ ...this.state, selectedPay: selectedPay });
  };

  setBank = (event) => {
    this.setState({ ...this.state, bank: event.target.value });
  };

  handleModalTerms = () => {
    this.setState({ modalIsShown: !this.state.modalIsShown });
  };

  setTokenLukaUpdate = (token, id) => {
    this.setState({ tokenLuka: { token: token, id: id } });
    let EmailInput = document.getElementById("email");
    let FirstNameInput = document.getElementById("firstName");
    let LastNameInput = document.getElementById("lastName");
    if (EmailInput) {
      EmailInput.addEventListener("change", () => {
        window.luka.updateEmail(EmailInput.value, token);
      });
      FirstNameInput.addEventListener("change", () => {
        window.luka.updateNombrePagador(
          FirstNameInput.value + " " + LastNameInput.value,
          token
        );
      });
      LastNameInput.addEventListener("change", () => {
        window.luka.updateNombrePagador(
          FirstNameInput.value + " " + LastNameInput.value,
          token
        );
      });
    }
  };

  setPaymentFailure = () => {
    this.setState({ paymentFailure: true });
  };

  handleCheck = (e) => {
    this.setState({ ...this.state, checkTerms: e.target.checked });
  };

  scrollToFormPay = () => window.scrollTo(0, this.myRef.current.offsetTop);

  validateForm = (values, valid) => {
    // console.log(this.captchaRef.current.getValue())
    if (values) {
      this.setState({ formData: values, email: values.email });
    }
    this.setState({ formValid: valid });
  };
  getParameterByName(name) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
      results = regex.exec(document.location.search);
    return results === null
      ? ""
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  }
  reservarGratis = async () => {
      this.setState({loading:true})
    let resp = await this.validateFormPay();
    if (resp) {
      const FirstName = document.getElementById("firstName")
        ? document.getElementById("firstName").value
        : "";
      const LastName = document.getElementById("lastName")
        ? document.getElementById("lastName").value
        : "";
      const PhoneNumber = document.getElementById("phoneNumber")
        ? document.getElementById("phoneNumber").value
        : "";
      // console.log(document.getElementById("email"));
      const Email = document.getElementById("email")
        ? document.getElementById("email").value
        : "";
      const Nacionalidad = document.getElementById("nacionalidad")
        ? document.getElementById("nacionalidad").value
        : "00000000";
      const Cedula = document.getElementById("cedula")
        ? document.getElementById("cedula").value
        : "";
      const body = {
        lukaregistrodto: {
          AplicarConversion: true,
          Canal: "Pasarela de pago",
          CargosAdicionales: null,
          Cuotas: null,
          Descripcion: "success",
          Exitoso: true,
          FechaOperacion: null,
          InfoProceso: {
            CodigoRespuestaCvv: "ND",
            EstatusProcesamiento: "success",
          },
          InfoTarjeta: {
            Bin: "400000",
            CategoriaTarjeta: "CONSUMER",
            Ciudad: null,
            CodigoPostal: "12345",
            Descripcion: null,
            Direccion: {
              Ciudad: null,
              CodigoPostal: "12345",
              Direccion: null,
              Estado: null,
              Id: 0,
              IdPais: 0,
            },
            EstaBoveda: false,
            Estado: null,
            FechaVencimiento: "01/2025",
            Id: 0,
            IdStatus: 0,
            Moneda: null,
            Pais: "us",
            SubTipoTarjeta: "CREDIT",
            TipoTarjeta: "VISA",
            UltimosCuatroDigitos: "1000",
          },
          InfoUsuarioPagador: {
            Apellido: null,
            Email: Email,
            Nombre: FirstName,
            NumeroIdentidad: null,
            NumeroTelefono: null,
          },
          MedioDePago: "Crédito",
          MerchantId: null,
          Moneda: "USD",
          Monto: 0,
          MontoOriginal: null,
          MontoUsd: 0,
          TarjetaHabiente: {
            Apellido: null,
            LukapayId: null,
            Nombre: FirstName,
            NumeroIdentificacionPersonal: null,
            NumeroTelefono: null,
          },
          TransaccionId: 123456,
          TransaccionMerchantId: 12345678,
          TrazaId: "221756",
        },
        consumidorskin: {
          email: Email,
          detalleregistroId: this.state.detalleRegistro.Id,
          nombre: FirstName,
          apellido: LastName,
          telefono: PhoneNumber,
          enviarEticket: true,
          Idioma: this.props.global.Idioma.toLowerCase(),
          paisId: this.props.selectedFunction.PaisId,
          skinId: this.props.global.skinId,
          dni: Nacionalidad + Cedula,
          trazaId: "XXXXXXXXXXXXXXXXXXXXXx",
        },
        operadorid: this.getParameterByName("idOperador"),
      };
      const order_id = document.getElementById("order_id")
        ? document.getElementById("order_id").value
        : null;
      const { status, data } = await Services.pay.ProcessPayLuka(body);
      if (status === 200) {
        //console.log("compraexitosa",result)
        if (
          data.transaccion.error === "" &&
          data.transaccion.transaccionid !== 0
        ) {
          localStorage.removeItem("reservationSeats");
          this.props.history.push({
            pathname: `/compraexitosa/${data.transaccion.transaccionid}/2`,
            state: {
              confirmacion: data.confirmacion,
              selectedFunction: this.props.selectedFunction,
            },
          });
        } else {
          console.log(data);
        }
      } else {
        console.log(status);
      }
    } else {
      console.log("invalid");
    }
    this.setState({loading:false})
  };

  render() {
    const {
      global,
      resumeTickets,
      showID,
      reservationSeats,
      deleteReservation,
      setResumeTickets,
      totalMount,
      setTotalMount,
      t,
      selectedFunction,
    } = this.props;
    const {
      handleSelectPay,
      setBank,
      handleModalTerms,
      handleCheck,
      setTokenLukaUpdate,
      displayLuka,
      updateAmountLuka,
      setPaymentFailure,
      scrollToFormPay,
      updateAmountPayu,
      validateForm,
      errorMessage,
      errorLuka,
      updateValidCounter,
      updateEmailRegisterDetail,
    } = this;
    return (
      <section id="wrap-ppal">
        <GoTop />
        <div id="wrap-cont">
          <div className="container-custom-tm clear-fl">
            <div
              className="d-flex align-items-center wrap-back responsive_back_shop"
              onClick={this.props.back}
              style={{ cursor: "pointer" }}
            >
              <NewBack />
            </div>

            <div id="wrap-pago" className="wrap-pago clear-fl">
              <div ref={this.myRef}>
                <FormPay
                  global={global}
                  validCounter={this.state.validCounter}
                  validateForm={validateForm}
                  selectedFunction={selectedFunction}
                  deleteReservation={deleteReservation}
                />
              </div>
              <ResumePay
                carProds={this.props.carProds}
                deleteReservation={deleteReservation}
                Simbolo={
                  global.MonedaPorDefecto === "USD" &&
                  selectedFunction.Iso === "VES"
                    ? "$"
                    : selectedFunction.MonedaSimbolo
                }
                handlePay={this.props.handlePay}
                Iso={selectedFunction.Iso}
                selectedFunction={selectedFunction}
                resumeTickets={resumeTickets}
                setResumeTickets={setResumeTickets}
                detalleRegistro={this.state.detalleRegistro}
                showID={showID}
                back={this.props.back}
                reservationSeats={reservationSeats}
                totalMountPay={totalMount}
                global={global}
                setTotalMount={setTotalMount}
                updateAmountLuka={updateAmountLuka}
                updateAmountPayu={updateAmountPayu}
                selectedPay={this.state.selectedPay}
                conversion={
                  global.MonedaPorDefecto === "USD" &&
                  selectedFunction.Iso === "VES"
                }
              />
              {console.log(
                this.props.isGratis,
                "******************************"
              )}
              {this.state.detalleRegistro && !this.props.isGratis && (
                <PayMethods
                  global={global}
                  showSkeleton={this.props.showSkeleton}
                  initPay={this.state.initPay}
                  showID={showID}
                  setBank={setBank}
                  handleCheck={handleCheck}
                  checkTerms={this.state.checkTerms}
                  email={this.state.email}
                  totalMount={this.props.totalMount}
                  closeSkeleton={this.props.closeSkeleton}
                  detalleRegistro={this.state.detalleRegistro}
                  handleSelectPay={handleSelectPay}
                  handleModalTerms={handleModalTerms}
                  setTokenLukaUpdate={setTokenLukaUpdate}
                  displayLuka={displayLuka}
                  selectedFunction={selectedFunction}
                  formValid={this.state.formValid}
                  formData={this.state.formData}
                  setPaymentFailure={setPaymentFailure}
                  scrollToFormPay={scrollToFormPay}
                  errorTerms={this.state.errorTerms}
                  updatePayu={this.state.updatePayu}
                  updateAmountPayu={updateAmountPayu}
                  history={this.props.history}
                  updateValidCounter={updateValidCounter}
                  validateFormPay={this.validateFormPay}
                  handleErrorMessage={this.handleErrorMessage}
                  handleErrorLuka={this.handleErrorLuka}
                  updateEmailRegisterDetail={updateEmailRegisterDetail}
                />
              )}

              {this.props.isGratis ? (
                <div
                  className="content"
                  style={{ marginTop: "35px", float: "left", width: "100%" }}
                >
                  <button
                    className={`btn-skin btn-get-tk btn-ppal active btn-pay-gratis`}
                    onClick={() => {
                      this.reservarGratis();
                    }}
                  >
                    {this.state.loading ? (
                      <div className="loading inside">
                        <div></div>
                      </div>
                    ) : (
                      <span>Finalizar compra</span>
                    )}
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div
          className={
            this.state.modalIsShown &&
            global.TextoTeminosCondiciones.trim() !== ""
              ? "modal fade in"
              : "modal fade"
          }
          id="modalTerminos"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="modalTerminos"
          aria-hidden="true"
          style={{
            display: this.state.modalIsShown ? "block" : "none",
          }}
        >
          <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div className="modal-content">
              <div className="conten-terms clear-fl">
                <div
                  className="cerrar"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() =>
                    this.setState({
                      modalIsShown: !this.state.modalIsShown,
                    })
                  }
                >
                  <i className="icon-cerrar-simple"></i>
                </div>
                <div className="title sub-title-bold color-titulos">
                  {t("termsAndConditions").toUpperCase()}
                </div>
                <div className="wrap-terms">
                  <div
                    className="text color-texto-contenido"
                    style={{ overflowX: "hidden" }}
                  >
                    <p style={{ whiteSpace: "pre-line" }}>
                      {global.TextoTeminosCondiciones.replace(
                        /<br>/gm,
                        "\n"
                      ).replace(/\/\*\//gm, "\n")}
                    </p>
                  </div>
                </div>
                <a
                  className="btn-skin btn-modal-term btn-ppal active"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    this.setState({
                      modalIsShown: !this.state.modalIsShown,
                    });
                    if (this.state.selectedPay === "LUKA") {
                      window.luka.terminosOk();
                    } else {
                      this.setState({ checkTerms: true });
                    }
                  }}
                >
                  {t("agree")}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade modalAlert" id="modalExpired" role="dialog">
          <div
            className="modal-dialog modal-dialog-centered modal-md"
            role="document"
          >
            <div className="modal-content">
              <div className="conten-expired clear-fl">
                <div className="top">
                  <i className="icon-timer color-b"></i>
                </div>
                <div className="">
                  <div className="title color-titulos">{t("timeExpired")}</div>
                  <p className="color-texto-contenido">
                    {t("timeAvailableExpired")}
                  </p>
                  <a
                    href=""
                    data-dismiss="modal"
                    aria-label="Close"
                    className="color-ppal hover-text-ppal"
                  >
                    {t("tryAgain").toUpperCase()}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            this.state.paymentFailure
              ? "modal fade modalAlert in"
              : "modal fade modalAlert"
          }
          style={{
            display: this.state.paymentFailure ? "block" : "none",
          }}
          id="modalFailure"
          role="dialog"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-md"
            role="document"
          >
            <div className="modal-content">
              <div className="conten-expired clear-fl">
                <div className="top">
                  <i className="icon-cerrar color-b"></i>
                </div>
                <div className="content">
                  {this.state.errorLuka ? (
                    <div className="title color-titulos">
                      {t("paymentError")}
                    </div>
                  ) : (
                    <div className="title color-titulos">
                      Error al procesar pago
                    </div>
                  )}
                  <p className="color-texto-contenido">
                    {this.state.errorMessage}
                    {/* {t('paymentErrorMsg')} */}
                  </p>

                  {errorLuka && (
                    <p className="color-texto-contenido">
                      {t("tryAgain").toUpperCase()}
                    </p>
                  )}
                  <a
                    href=""
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({
                        paymentFailure: false,
                      });
                    }}
                    data-dismiss="modal"
                    aria-label="Close"
                    className="color-ppal hover-text-ppal"
                  >
                    {t("labelOK").toUpperCase()}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withTranslation()(Purchase);
