import React from 'react'

const GoTop = () => {
  React.useEffect(()=>{
    window.scrollTo(0,0);
  },[])
  return (
    <></>
  )
}

export default GoTop
