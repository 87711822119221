import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import FormTimedOut from "../Forms/FormTimedOut";

const TimedOut = ({ t, global, event }) => {
    const [showNotified, setShowNotified] = useState(false);
    const [imgEvent, setImgEvent] = useState("");
    useEffect(() => {
        if (event.Id) {
            setImgEvent(
                process.env.REACT_APP_EVENT_IMG
                    ? process.env.REACT_APP_EVENT_IMG.replace(
                          "{{EVENT_ID}}",
                          event.Id
                      )
                    : ""
            );
        }
    }, [event]);

    const handleNotification = () => {
        setShowNotified(true);
    };
    return (
        <>
            <div className="wrap-evento clear-fl">
                <div
                    className="d-flex"
                    style={{ flexDirection: "column", width: "100%" }}
                >
                    <div className="wrap-ended">
                        <section className="content-timedout-one">
                            <div>
                                <figure className="image-event-timed">
                                    <img
                                        src={
                                            imgEvent ||
                                            "/images/evento/img-banner-wait.jpg"
                                        }
                                        alt="banner event"
                                    />
                                </figure>
                            </div>
                            <div className="timed-out-text">
                                <figure>
                                    <img
                                        src="/images/calendario.svg"
                                        alt="calendar icon warning"
                                    />
                                </figure>
                                <p>
                                    {global.LabelAgotado &&
                                    global.LabelAgotado.trim() !== ""
                                        ? global.LabelAgotado
                                        : t("timedOut")}
                                </p>
                            </div>
                        </section>
                        {showNotified ? (
                            <div className="notified-message">
                                <svg
                                    width="46"
                                    height="46"
                                    viewBox="0 0 46 46"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <circle
                                        cx="23"
                                        cy="23"
                                        r="21.4286"
                                        fill="white"
                                        stroke="#1CD31C"
                                        strokeWidth="2.85714"
                                    />
                                    <path
                                        d="M12.0996 22.81L20.1794 31.1872L34.0037 16.854"
                                        fill="white"
                                    />
                                    <path
                                        d="M12.0996 22.81L20.1794 31.1872L34.0037 16.854"
                                        stroke="#1CD31C"
                                        strokeWidth="2.85714"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </svg>
                                <h4>
                                    Listo, ya te encuentras en la lista de
                                    espera para una próxima función
                                </h4>
                                <a
                                    href="https://ticketmundo.com"
                                    className="btn-skin btn-pago btn-ppal active"
                                >
                                    Ver otros eventos
                                </a>
                            </div>
                        ) : (
                            <section className="content-timedout-two">
                                <p className="text-form-timeout">
                                    <svg
                                        width="40"
                                        height="40"
                                        viewBox="0 0 40 40"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <circle
                                            cx="20"
                                            cy="20"
                                            r="20"
                                            fill="#353B47"
                                        />
                                        <path
                                            d="M25.8657 13.75C25.7972 13.75 25.7269 13.7609 25.6566 13.7859L15.2647 17.4047H12.308C12.1493 17.4047 12.0195 17.5203 12.0195 17.6641V22.3359C12.0195 22.4797 12.1493 22.5953 12.308 22.5953H14.1415C14.0748 22.7766 14.0388 22.9688 14.0388 23.1641C14.0388 24.1938 15.0087 25.0313 16.2022 25.0313C17.201 25.0313 18.043 24.4438 18.2918 23.65L25.6584 26.2156C25.7287 26.2391 25.799 26.2516 25.8675 26.2516C26.1722 26.2516 26.4444 26.0297 26.4444 25.7328V14.2688C26.4426 13.9719 26.1722 13.75 25.8657 13.75ZM16.2022 23.9109C15.7245 23.9109 15.3368 23.5766 15.3368 23.1641C15.3368 22.9891 15.4072 22.8219 15.5352 22.6891L17.0658 23.2219C17.0297 23.6063 16.6566 23.9109 16.2022 23.9109ZM25.1445 24.8234L15.7479 21.5516L15.5153 21.4703H13.3176V18.5297H15.5153L15.7479 18.4484L25.1445 15.1766V24.8234Z"
                                            fill="white"
                                        />
                                    </svg>
                                    <span>
                                        Avísame si vuelve a venir y me colocas
                                        en lista de espera
                                    </span>
                                </p>
                                <FormTimedOut
                                    event={event}
                                    notification={handleNotification}
                                />
                            </section>
                        )}
                    </div>
                </div>
            </div>
            {/* <div
                className={
                    showModal
                        ? "modal fade modalAlert in"
                        : "modal fade modalAlert"
                }
                style={{
                    display: showModal ? "block" : "none",
                }}
                id="modalFailure"
                role="dialog"
            >
                <div
                    className="modal-dialog modal-dialog-centered modal-md"
                    role="document"
                >
                    <div className="modal-content">
                        <div className="conten-expired clear-fl">
                            <div className="top">
                                <i className="icon-mail color-b"></i>
                            </div>
                            <div className="content">
                                <div className="title color-titulos"></div>
                                <p className="color-texto-contenido">
                                    {t("newsletterAdded")}
                                </p>
                                <a
                                    href={global.inicio}
                                    onClick={(e) => {
                                        setShowModal(false);
                                    }}
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    className="color-ppal hover-text-ppal"
                                >
                                    {t("labelOK").toUpperCase()}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    );
};

TimedOut.propTypes = {};

export default withTranslation()(TimedOut);
