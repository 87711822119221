/**
 *
 * VARIABLES URL PROD Y DEV GLOBALES
 */
//PROD
export const URL_BASE = process.env.REACT_APP_URL_BASE;
export const URL_BASE_2 = process.env.REACT_APP_URL_BASE_2;
//export const URL_API = 'https://apitm.ticketmundo.com/api/';
export const URL_API = process.env.REACT_APP_URL_API;
//export const URL_API = 'https://apivinotinto.azurewebsites.net/api/';
//export const URL_API = 'https://api-tm-vinotinto.azurewebsites.net/api/';
//export const URL_API = 'https://api.ticketmundo.com/api/';
//export const URL_API = 'https://apisgb-staging.azurewebsites.net/api/';
//luka https://lukapagos.com/dist/luka.v2.min.js
// IMAGENES CAMBIAR EN ENV.LOCAL REACT_APP_EVENT_IMG=https://ticketmundoblob.blob.core.windows.net/imagenestmusaprueba/Evento_{{EVENT_ID}}_517_262.jpeg

//DEV
// export const URL_BASE = 'https://apisgbtest.ticketmundo.com/';
// export const URL_BASE_2 =
//   'https://portalweb-usa-portal-we-usa-calidad.azurewebsites.net';
// export const URL_API = 'https://apitmtest.ticketmundo.com/api/';
//https://bspayco-qa.payco.net.ve/dist/luka.v2.min.js
// IMAGENES CAMBIAR EN ENV.LOCAL REACT_APP_EVENT_IMG=https://ticketmundoblob.blob.core.windows.net/imgtest/Evento_{{EVENT_ID}}_517_262.jpeg

export const setFavicon = (favImg) => {
  let favicon = document.getElementById('favicon');
  favicon.href = favImg;
};

export const createCookie = (name, value, days) => {
  let expires;
  if (days) {
    let date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toGMTString();
  } else {
    expires = '';
  }

  document.cookie = name + '=' + value + expires + '; path=/';
};

export const readCookie = (name) => {
  var nameEQ = name + '=';
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1, c.length);
    }
    if (c.indexOf(nameEQ) === 0) {
      return c.substring(nameEQ.length, c.length);
    }
  }
  return null;
};

export const deleteCookie = (name) => {
  createCookie(name, '', -1);
};

export const emailRegex = RegExp(
  /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
);

export const formValid = ({ formErrors, formData }) => {
  let valid = true;
  // validate form errors being empty
  Object.values(formErrors).forEach((val) => {
    val.length > 0 && (valid = false);
  });

  // // validate the form was filled out
  // Object.values(formData).forEach(val => {
  //   val === "" && (valid = false);
  // });

  return valid;
};

export const currencyFormatValue = (change, value, MostrarDecimales) => {
  
  let result;
  if (MostrarDecimales === true) {
    result = value / change;
  } else {
    result = Math.floor(value / change);
  }
  return result;
};

export const currencyFormatDE = (
  num,
  thousandsSeparator = ',',
  decimalSeparator = '.',
  showDecimals = false
) => {
  let numero = 0;
  if (num) {
    numero = num
      .toFixed(showDecimals ? '2' : '0')
      .replace('.', decimalSeparator) // replace decimal point character with ,
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${thousandsSeparator}`)
  }
  return numero
};

export const currencyFormatDonation = (
  num,
  thousandsSeparator = ',',
  decimalSeparator = '.',
  showDecimals = false
) => {
  if (showDecimals) {
    return Number(num.replaceAll(thousandsSeparator, '').replaceAll(decimalSeparator, '.'));
  } else {
    return Number(num.replace(/,/gi, '').replace(/\./gi, ''));
  }
};
export const eliminarCaracteres = (cadena) =>
  decodeURI(cadena)
    .normalize('NFD')
    .replace(/\s/g, '-')
    .replace(/[#?+&'\u0300-\u036f]/g, '');

export const urlEvent = () => {
  let evt = window.location.pathname.split('/').filter((x) => x.length > 0);
  evt[1] = eliminarCaracteres(evt[1]).substring(0, 50);
  return (
    window.location.protocol +
    '//' +
    window.location.host +
    '/' +
    evt.join('/') +
    window.location.search
  );
};
export const urlFunction = (limit) => {
  let evt = window.location.pathname
    .split('/')
    .filter((x, index) => x.length > 0 && index <= limit);
  return (
    window.location.protocol +
    '//' +
    window.location.host +
    '/' +
    evt.join('/') +
    window.location.search
  );
};
