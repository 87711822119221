import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import Services from "../../../../services/Services";
import SkeletonLuka from "../../../../components/Loader/skeletonLuka";
import { emailRegex } from "../../../../utils/globals";
import Swal from "sweetalert2/dist/sweetalert2.js";

const FormPay = (props) => {
    const [tokenLuka, setTokenLuka] = useState({});
    const {
        global,
        initPay,
        showID,
        detalleRegistro,
        handleModalTerms,
        setTokenLukaUpdate,
        handleCheck,
        t,
        formValid,
        formData,
        setPaymentFailure,
        scrollToFormPay,
        selectedFunction,
        errorTerms,
        updatePayu,
        updateAmountPayu,
        updateValidCounter,
        updateEmailRegisterDetail,
        checkTerms,
        handleErrorMessage,
        handleErrorLuka,
        history,
        validateFormPay,
        totalMount,
        closeSkeleton,
        email,
        deleteReservation,
        showSkeleton,
    } = props;
    //console.log(props.formData);
    // console.log("global:",global);
    useEffect(() => {
        initPayLuka();
    }, []);
    useEffect(() => {
        if (tokenLuka.token && tokenLuka.token.trim() !== "") {
            displayLuka();
        }
    }, [tokenLuka]);
    // useEffect(() => {
    //     //console.log(formData);
    //     if (
    //         formData.firstName.trim() !== "" &&
    //         formData.lastName.trim() !== "" &&
    //         tokenLuka.token &&
    //         tokenLuka.token.trim() !== ""
    //     ) {
    //         //displayLuka();
    //     }
    // }, [formData.firstName, formData.lastName]);

    const displayLuka = async () => {
        const btnColor = getComputedStyle(document.body)
            .getPropertyValue("--c1")
            .replace("#", "");
        const { deleteReservation } = props;
        // console.log(global.lukaMethods, global.paymentMethods);
        let methods =
            global.lukaMethods.trim() !== ""
                ? global.lukaMethods
                : global.paymentMethods;
                console.log(document.body.getBoundingClientRect().width,"widthhhhhhhhhhhhhhhhhhhhhhhhh");

        // console.log("methodsss: ", methods);
        // // console.log("traza: ",detalleRegistro ? detalleRegistro.Id.toString():"NOIDREGISTRO" );
        //   console.log("displayLuka",selectedFunction.CodigoPagoCuotas)
        //   console.log("deleteReservation----->",deleteReservation)
        try {
            if (tokenLuka.token !== "") {
                window.luka.init(
                    tokenLuka.token,
                    "luka-container",
                    {
                        monto: parseFloat(
                            totalMount.toFixed(
                                global.MostrarDecimales ? "2" : "0"
                            )
                        ),
                        numeroDecimales: global.MostrarDecimales ? 2 : 0,
                        separadorMiles: global.thousandsSeparator,
                        separadorDecimal: global.decimalSeparator
                    },
                    email === "" || !emailRegex.test(email) ? null : email,
                    detalleRegistro
                        ? detalleRegistro.Id.toString()
                        : new Date().getTime(),
                    {
                        moneda:
                            selectedFunction.Iso === "VES"
                                ? global.MonedaPorDefecto
                                : selectedFunction.Iso,
                        seleccion: 2,
                        terminos: {
                            tipo: global.CheckTeminosCondiciones ? "1" : "2",
                            comportamiento:
                                !global.comportamientoTerms ||
                                global.comportamientoTerms === 1
                                    ? handleModalTerms
                                    : global.TextoCondicionesExtras,
                        },
                        idioma:
                            global.Idioma.toLowerCase() === "es"
                                ? "esp"
                                : "eng",
                        color: btnColor.trim(),
                        fuente: global.font,
                        showSkeletonLoading: false,
                        horizontal:document.body.getBoundingClientRect().width< 800,
                        fnValidacionEsAsync: true,
                        metodos: selectedFunction.Paypal
                            ? methods
                                  .split(",")
                                  .filter((x) => x.length > 0)
                                  .concat("paypal")
                                  .join()
                            : methods,
                    },
                    callBackLukaPay,
                    validateFormPay,
                    () => {
                        closeSkeleton()
                    },
                    {
                        nombrePagador: `${
                            document.getElementById("firstName").value
                        } ${document.getElementById("lastName").value}`,
                        cuotasConfig:{
                            idConfig: parseInt(selectedFunction?.CodigoPagoCuotas)
                        },
                    },
                );
            }
        } catch (error) {}
    };

    const callBackLukaPay = async (result, error) => {
        const FirstName = document.getElementById("firstName")
            ? document.getElementById("firstName").value
            : "";
        const LastName = document.getElementById("lastName")
            ? document.getElementById("lastName").value
            : "";
        const PhoneNumber = document.getElementById("phoneNumber")
            ? document.getElementById("phoneNumber").value
            : "";
        // console.log(document.getElementById("email"));
        const Email = document.getElementById("email")
            ? document.getElementById("email").value
            : "";
        const Nacionalidad = document.getElementById("nacionalidad")
            ? document.getElementById("nacionalidad").value
            : "00000000";
        const Cedula = document.getElementById("cedula")
            ? document.getElementById("cedula").value
            : "";
       

        if (error && error.Error.length > 0) {
            console.log("error:", error, result);
        }
        /*console.log("result----------------------------------", result);
        console.log("error----------------------------------", error);*/
        if (result === null || result === undefined || !result.Exitoso) {
            result = {
                Monto: totalMount,
                MontoUsd: totalMount,
                TarjetaHabiente: {
                    Nombre: FirstName,
                    Apellido: LastName,
                    NumeroTelefono: PhoneNumber,
                },
                Moneda:
                    selectedFunction.Iso === "VES"
                        ? global.MonedaPorDefecto
                        : selectedFunction.Iso,
                TransaccionId:
                    result && result.TransaccionId ? result.TransaccionId : 0,
                TransaccionMerchantId: 0,
                Descripcion:
                    error && error.Error.length > 0
                        ? error.Error[0].Descripcion
                        : result &&
                          result.Descripcion &&
                          result.Descripcion.trim() !== ""
                        ? result.Descripcion
                        : "Error de Luka",
                TrazaId:
                    error && error.TrazaId
                        ? error.TrazaId
                        : result && result.TrazaId
                        ? result.TrazaId
                        : "",
                Exitoso: false,
                MedioDePago:
                    result && result.MedioDePago ? result.MedioDePago : "Luka",
                Canal: "API",
            };
        }

        result.AplicarConversion =
            selectedFunction.Iso === "VES" &&
            global.dolarToday &&
            global.MonedaPorDefecto === "USD" &&
            global.CambioDeMoneda;
        const body = {
            lukaregistrodto: result,
            consumidorskin: {
                email: Email,
                detalleregistroId: detalleRegistro.Id,
                nombre: FirstName,
                apellido: LastName,
                telefono: PhoneNumber,
                enviarEticket: true,
                Idioma: global.Idioma.toLowerCase(),
                paisId: selectedFunction.PaisId,
                skinId: global.skinId,
                dni: Nacionalidad + Cedula,
                trazaId: result.TrazaId,
            },
            operadorid: getParameterByName("idOperador"),
        };
        const order_id = document.getElementById("order_id")
            ? document.getElementById("order_id").value
            : null;
        const { status, data } =
        selectedFunction.DDBranchId && order_id
            ? await Services.pay.ProcessPayLukaWithMerch({
                  lukaTransaccionRegistrarDto: body,
                  order_id: order_id,
              })
            : await Services.pay.ProcessPayLuka(body);
        if (
            result &&
            result?.InfoProceso &&
            result?.InfoProceso?.EstatusProcesamiento === "pending"
        ) {
            console.log("pending",result,data)
            if((result.MedioDePago === "Pago en Cuotas") && (data.UsaCuotas=== true) ){
                localStorage.removeItem("reservationSeats")
                history.push({
                    pathname: `/compraparcial`,
                    state: {
                        Nombre: FirstName,
                        Usuario: result.InfoUsuarioPagador.Nombre,
                        Cuotas:result.Cuotas,
                        Monto: totalMount,
                        Email: Email,
                        Dni: Nacionalidad ? `${Nacionalidad}-${Cedula}` : 0,
                        Ref: result.TransaccionId,
                        selectedFunction:selectedFunction,
                        global:global,
                        FechaFinReserva:data?.FechaFinReserva
                    },
                });
            }else if(data.Message === "Pago pendiente por procesar"){
                localStorage.removeItem("reservationSeats")
                history.push({
                    pathname: result.MedioDePago === 'Efectivo' ? `/compraefectivo`: `/compraenproceso`,//`/pendingReview`,
                    state: {
                        Nombre: FirstName,
                        Usuario: result.InfoUsuarioPagador.Nombre,
                        Monto: totalMount,
                        Email: Email,
                        Dni: Nacionalidad ? `${Nacionalidad}-${Cedula}` : 0,
                        Ref: result.TransaccionId,
                        selectedFunction:selectedFunction,
                        global:global,
                        FechaFinReserva:data?.FechaFinReserva
                    },
                });
            }else{
                handleErrorLuka(
                    true,
                    data.Message
                );
            }
           
            //return;
        }
        if (status === 200) {
            //console.log("compraexitosa",result)
            if(result.Exitoso && result.MedioDePago === "Pago en Cuotas" ){
                // console.log("Exitoso",result,data)
                localStorage.removeItem("reservationSeats")
                history.push({
                    pathname: `/compraparcial`,
                    state: {
                        Nombre: FirstName,
                        Usuario: result.InfoUsuarioPagador.Nombre,
                        Cuotas:result.Cuotas,
                        Monto: totalMount,
                        Email: Email,
                        Dni: Nacionalidad ? `${Nacionalidad}-${Cedula}` : 0,
                        Ref: result.TransaccionId,
                        selectedFunction:selectedFunction,
                        global:global,
                        FechaFinReserva:data?.FechaFinReserva
                    },
                });
            } else if (
                result.Exitoso &&
                data.transaccion.error === "" &&
                data.transaccion.transaccionid !== 0
            ) {
                localStorage.removeItem("reservationSeats")
                history.push({
                    pathname: `/compraexitosa/${data.transaccion.transaccionid}/2`,
                    state: {
                        confirmacion: data.confirmacion,
                        selectedFunction: selectedFunction,
                    },
                });
            } else {
                if (result.Exitoso) {
                    handleErrorMessage(
                        "Su transaccion fue registrada, si no ha recibido un correo con sus tickets puede comunicarse al correo atencionalcliente@ticketmundo.com"
                    );
                    console.log(
                        "transaccion fallida:  comuniquese con el administrador"
                    );
                } else {
                    handleErrorLuka(
                        true,
                        `Hemos recibido el siguiente error al registrar su pago:${"\b"} ${
                            result.Descripcion
                        } ${"\b"} Por favor Intente de nuevo`
                    );
                    //this.handleErrorMessage();
                    console.log("transaccion fallida:  ", result.Descripcion);
                }
                //this.setState({ paymentFailure: true });
            }
        } else if (status === 409) {
            if (result.Exitoso && data.aprobado) {
                localStorage.removeItem("reservationSeats")
                history.push({
                    pathname: `/compraexitosa/${data.transaccion_id}/2`,
                    state: {
                        confirmacion: null,
                        selectedFunction: selectedFunction,
                    },
                });
            }
        } else {
            console.log("error al llamar servicio", status);
        }
    };

    const initPayLuka = async () => {
        try {
            //const { selectedFunction } = props;
            // if (selectedFunction.PaisId !== this.state.chile) {
            const lukaresponse = await Services.pay.initPayLuka(
                selectedFunction.ComercioId,
                selectedFunction.PaisId
            );
            if (lukaresponse.status === 200) {
                setTokenLukaUpdate(
                    lukaresponse.data.Token,
                    lukaresponse.data.Id
                );
                setTokenLuka({
                    token: lukaresponse.data.Token,
                    trazaId: lukaresponse.data.Id,
                });
            } else {
                console.log(lukaresponse.status);
            }
        } catch (error) {
            console.log(error);
        }
    };
    return (
        <section className="content">
            <div className="method-pay-mobile">
                <div className="title-g sub-title-bold color-titulos">
                    <span>{t("labelPaymentMethods")}</span>
                </div>
                <div className="info-pago">
                    <div className="wrap-cont">
                        {showSkeleton && <SkeletonLuka />}
                        <div
                            style={
                                showSkeleton
                                    ? {
                                          display: "block",
                                          height: 0,
                                          overflow: "hidden",
                                      }
                                    : { display: "block" }
                            }
                            id="luka-container"
                        ></div>
                    </div>
                </div>
            </div>
        </section>
    );
};

const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

function getParameterByName(name) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
        results = regex.exec(document.location.search);
    return results === null
        ? ""
        : decodeURIComponent(results[1].replace(/\+/g, " "));
}

export default withTranslation()(React.memo(FormPay));
