import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ADD_PRODUCTO } from "../../../../../reducers/carReducer";
import { getProductoCar } from "../../services/car";

const ButtonAdd = ({
    car,
    producto,
    variantes,
    disabled,
    cantidad,
    label,
    inCar,
    onClick,
}) => {
    const dispatch = useDispatch();
    const { MonedaPorDefecto } = useSelector((store) => store.global);
    const MonedaTienda = useSelector((state) => state.event.selectedFunction.Iso === 'VES' ? state.global.MonedaPorDefecto : state.event.selectedFunction.Iso);

    const handleClick = async () => {
        if (typeof onClick === "function") {
            onClick();
        } else {
            if (inCar) {
                if (label === "Modificar") {
                    // dispatch({type:CAR_ACTIONTYPES.UPDATE_PRODUCT,payload:{car,id:inCar.id,cantidad:cantidad || 1}})
                } else {
                    // dispatch({type:CAR_ACTIONTYPES.REMOVE_PRODUCT,payload:{id:inCar.id,car}})
                }
            } else {
                const prodDataExtra = await getProductoCar(producto,MonedaTienda);
                dispatch({
                    type: ADD_PRODUCTO,
                    payload: {
                        car,
                        producto:{...producto, ...prodDataExtra},
                        variantes,
                        cantidad: cantidad || 1,
                    },
                });
            }
        }
    };
    return (
        <button
            className={`${disabled ? "disabled" : ""} buttonAdd ${label}`}
            onClick={handleClick}
            disabled={disabled}
            onKeyDown={() => null}
        >
            <span>{label || "Agregar"}</span>
        </button>
    );
};

export default ButtonAdd;
