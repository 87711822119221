import React, { useRef } from "react";
import PropTypes from "prop-types";
import Skeleton from "react-loading-skeleton";
import { withTranslation } from "react-i18next";

const Header = (props) => {
    const { global, confirmation, customer, t, getIdioma, translate, email } = props;
    const button = useRef(null);

    const cambiarIdioma = () => {
        let idioma = global.Idioma.toLowerCase() === "es" ? "en" : "es";
        getIdioma(idioma);
    };
    const buttonClick = () => {
        button.current.classList.toggle("active");
    };
    const buttonBlur = () => {
        setTimeout(() => {
            button.current.classList.remove("active");
        }, 300);
    };
    return !confirmation ? (
        global.header !== "none" ? (
            <div className="header-ev">
                <div className="container-custom-tm" style={{ height: "100%" }}>
                    <div className="wrap-header container-header-home">
                        <div>
                            {" "}
                            {global.header === "show" ? (
                                <a href={global.inicio}>
                                    <figure className="logo-home">
                                        <img src={global.logo} alt="" />
                                    </figure>
                                </a>
                            ) : (
                                <Skeleton height={60} width={90} />
                            )}
                        </div>
                        {translate && (
                            <div id="lang">
                                <button
                                    onClick={buttonClick}
                                    onBlur={buttonBlur}
                                    ref={button}
                                >
                                    <span className="color-texto-contenido">
                                        {global.Idioma.toLowerCase() === "es"
                                            ? "Español"
                                            : "English"}
                                    </span>
                                    <i className="icon-idioma"></i>
                                </button>
                                <section className="options">
                                    <a
                                        onClick={cambiarIdioma}
                                        className={
                                            global.Idioma.toLowerCase() ===
                                                "es" && "active"
                                        }
                                    >
                                        Español
                                    </a>
                                    <a
                                        onClick={cambiarIdioma}
                                        className={
                                            global.Idioma.toLowerCase() ===
                                                "en" && "active"
                                        }
                                    >
                                        Ingles
                                    </a>
                                </section>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        ) : null
    ) : (
        <div className="header header-confirm">
            <div className="header-ev title-h">
                {global.logoFooter && (
                    <a href={global.inicio ? global.inicio : "#"}>
                        <figure className="logo-home logo-conf-top">
                            <img src={global.logo} alt="" />
                        </figure>
                    </a>
                )}
                <div className="iconcheck">
                    <img src="https://cdn.ticketmundo.live/commons/success.gif" width="90" alt="success" />
                </div>
                <h1 className="clear-fl">
                    <span className="showA">{`${t(
                        "superb"
                    )} ${customer}!`}</span>
                    <span className="showA">{t("confirmedUser")}</span>
                </h1>
                <p>{t('confirmationDetails')}</p>
                <p><b>{email}</b></p>
            </div>
        </div>
    );
};

Header.propTypes = {
    global: PropTypes.object,
};

const IconCheck = ({color="var(--c1)"}) => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle cx="32" cy="32" r="30" fill="white" stroke={color} strokeWidth="4"/>
  <path d="M16.7383 31.7339L28.05 43.4619L47.4041 23.3955" fill="white"/>
  <path d="M16.7383 31.7339L28.05 43.4619L47.4041 23.3955" stroke={color} strokeWidth="4" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
);

export default withTranslation()(Header);
