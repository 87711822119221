import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getIdioma } from '../../actions/global/creators';
import Header from './Header';

const mapStateToProps = state => ({
    global: state.global
});

const mapDispatchToProps = dispatch => ({
    getIdioma: (idioma) => dispatch(getIdioma(idioma)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));