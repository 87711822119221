import React from "react";
import { Switch, Route } from "react-router-dom";
import i18n from "i18next";
import moment from "moment";
import Home from "../Home";
import Tickets from "../Tickets";
import TicketsPre from "../TicketsPre";
import Donations from "../Donations";
import ConfirmationPurchase from "../ConfirmationPurchase";
import succespage from "../succespage";
import succespage2 from "../succespage2";
import succespage3 from "../succespage3";
import Confirmation from "../Donations/Confirmation";
import { Helmet } from "react-helmet";
// import "./../../assets/scss/style.scss";
import { setFavicon } from "./../../utils/globals";
import "./../../assets/css/bootstrap.css";
import "./../../assets/css/container.css";
import "./../../assets/css/index.css";
import "./../../assets/css/theme-default.css";
import "./../../assets/css/responsive.css";

class App extends React.Component {
    componentDidMount() {
        const { getGlobalConfig, getDolarToday } = this.props;
        const domain = window.location.hostname;
        getDolarToday();
        getGlobalConfig(domain);
        // getGlobalConfig("ccam.ticketmundo.com");
        // getGlobalConfig("usa-ticketmundo-web-app-skin-emergencia.azurewebsites.net");
        // getGlobalConfig("usa-ticketmundo-web-app-skin-emergencia.azurewebsites.net");
        // getGlobalConfig("usa-ticketmundo-web-app-banesco-test.azurewebsites.net");
    }

    componentDidUpdate(prevProps) {
        if (this.props.global.Idioma !== prevProps.global.Idioma) {
            i18n.changeLanguage(
                this.props.global
                    ? this.props.global.Idioma.toLowerCase()
                    : "en"
            );
        }
    }
    render() {
        const { global } = this.props;
        const metaFace = global.hasOwnProperty("FacebookVerificacion")
            ? global.FacebookVerificacion
            : null;
        document.title = global.appTitle;
        //setFavicon(global.Ico);
        moment.locale(global.Idioma.toLowerCase());
        return (
            <>
                <Helmet>
                    <link rel="stylesheet" href={global.estilos} />
                    <title>{global.Identificador}</title>
                    {metaFace && (
                        <meta
                            name="facebook-domain-verification"
                            content={metaFace}
                        />
                    )}
                    <script src={process.env.REACT_APP_LUKA_URL}/>
                    <style>
                        {/* {`
                               .mainE{
                                    --font:${global.font} !important;
                                }
                            `} */}
                    </style>
                </Helmet>
                {/* {console.log(global)} */}
                {global && (
                    <Switch>
                        <Route
                            path="/event/preselection/:eventId"
                            component={TicketsPre}
                        />
                        <Route path="/event/:eventId" component={Home} />
                        <Route path="/donations/:orgId" component={Donations} />
                        <Route path="/festival/:orgId" component={Donations} />
                        <Route path="/show/:name/:showID" component={Tickets} />
                        <Route
                            path="/donacionexitosa/:transaccion"
                            component={Confirmation}
                        />
                        <Route path="/compraenproceso" component={succespage} />
                        
                        <Route path="/compraefectivo" component={succespage2} />
                        <Route path="/compraparcial" component={succespage3} />
                        <Route
                            path="/compraexitosa/:transaccion/:skinID"
                            component={ConfirmationPurchase}
                        />
                    </Switch>
                )}
            </>
        );
    }
}

export default App;
