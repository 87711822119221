import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

const Footer = (props) => {
    const { global } = props;
    const { t } = useTranslation();
    return (
        <footer className="footer-new">
            {/* <div className="container-custom-tm wrap clear-fl">
                {global.logoFooter ? (
                    <article>
                        <img src={global.logoFooter} alt="" className="logo" />
                    </article>
                ) : null}

                <article className="info clear-fl">
                    <div className="redes">
                        <a
                            href={global.facebook}
                        
                            rel="noopener noreferrer"
                        >
                            <IconFace color="#fff" />
                        </a>
                        <a
                            href={global.instagram}
                        
                            rel="noopener noreferrer"
                        >
                            <IconInst color="#fff" />
                        </a>
                    </div>

                    <div className="dir">
                        <span>{global.direccion1}</span>
                        <span>{global.direccion2}</span>
                    </div>
                </article>

                <article className="dev">
                    <p>{t("tecnologyFooter")}</p>
                    <img src="/images/ticketmundo.svg" alt="" />
                </article>
            </div> */}
            <div className="container-custom-tm">
                <span className="">
                    <a
                        href="https://www.facebook.com/ticketmundo"
                        className="ld--logo-tm my-2"
                    >
                        <img
                            src={`https://ticketmundo.sfo2.cdn.digitaloceanspaces.com/commons/facebook-ticketmundo.svg`}
                            alt="facebook"
                        />
                    </a>
                    <a
                        href="https://instagram.com/ticketmundo_ve"
                        className="ld--logo-tm my-2"
                    >
                        <img
                            src={`https://ticketmundo.sfo2.cdn.digitaloceanspaces.com/commons/instagram-ticketmundo.svg`}
                            alt="instagram"
                        />
                    </a>
                    <a
                        href="https://twitter.com/Ticketmundo_ve"
                        className="ld--logo-tm my-2"
                    >
                        <img
                            src={`https://ticketmundo.sfo2.cdn.digitaloceanspaces.com/commons/x-ticketmundo.svg`}
                            alt="x"
                        />
                    </a>
                    <a
                        href="https://www.youtube.com/@TicketmundoVe"
                        className="ld--logo-tm my-2"
                    >
                        <img
                            src={`https://ticketmundo.sfo2.cdn.digitaloceanspaces.com/commons/youtube-ticketmundo.svg`}
                            alt="youtube"
                        />
                    </a>
                    <a
                        href="https://www.tiktok.com/@ticketmundo_ve"
                        className="ld--logo-tm my-2"
                    >
                        <img
                            src={`https://ticketmundo.sfo2.cdn.digitaloceanspaces.com/commons/tiktok-ticketmundo.svg`}
                            alt="tiktok"
                        />
                    </a>
                </span>
                <figure>
                    <img
                        className="ld--logo-tm my-2"
                        src={`https://ticketmundo.sfo2.cdn.digitaloceanspaces.com/commons/logo-footer.svg`}
                        alt="Ticketmundo"
                    />
                    <span className="verson">{process.env.REACT_APP_VERSION_APP}</span>
                </figure>
            </div>
        </footer>
    );
};

Footer.propTypes = { global: PropTypes.object };

const IconFace = ({ color = "inherit" }) => (
    <svg
        width="11"
        height="20"
        viewBox="0 0 11 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M6.62665 4.94748V7.15623H10.0708L9.58413 10.6378H6.58922V19.5477H2.99532V10.6378H0.000396729V7.15623H2.99532V4.61055C2.99532 1.65306 4.8297 0.00585938 7.45026 0.00585938C8.72311 0.00585938 9.80876 0.118168 10.1457 0.155603V3.26283H8.3113C6.88871 3.26283 6.62665 3.93669 6.62665 4.94748Z"
            fill={color}
        />
    </svg>
);
const IconInst = ({ color = "inherit" }) => (
    <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M17.824 5.26649C17.824 3.80647 17.2624 2.57106 16.1768 1.59771C15.5404 0.99873 14.8291 0.586929 14.0055 0.399748C13.6685 0.324873 13.3316 0.25 12.9572 0.25C12.9198 0.25 12.9198 0.25 12.8823 0.25H4.98328C4.87096 0.25 4.75865 0.287437 4.64635 0.287437C4.04733 0.36231 3.48579 0.512056 2.9617 0.774111C1.87604 1.33566 1.05243 2.15926 0.56575 3.24492C0.341141 3.73159 0.191422 4.29314 0.116516 4.81725C0.116516 4.92956 0.0791016 5.04187 0.0791016 5.15418V13.0533C0.0791016 13.1656 0.116516 13.2779 0.116516 13.3902C0.191422 14.0641 0.378586 14.7005 0.71553 15.262C1.27707 16.2728 2.06325 17.0215 3.11143 17.5082C3.63554 17.7703 4.19711 17.8826 4.7961 17.9575C4.94583 17.9575 5.13303 17.9949 5.28275 17.9949C7.75356 17.9949 10.2243 17.9949 12.6578 17.9949C14.9039 17.9949 16.9255 16.4974 17.562 14.3636C17.7117 13.8769 17.7865 13.3902 17.7865 12.9035C17.824 10.283 17.824 7.77473 17.824 5.26649ZM16.4388 12.7538C16.4388 13.9143 15.9896 14.8502 15.166 15.6364C14.6044 16.1605 13.9306 16.4226 13.1445 16.5349C12.9572 16.5723 12.8075 16.5723 12.6203 16.5723H5.20789C3.44838 16.5723 1.9135 15.3369 1.53914 13.6148C1.46425 13.3528 1.46425 13.0533 1.46425 12.7912V5.34136C1.46425 4.66751 1.61401 4.03108 1.95094 3.4321C2.51247 2.45875 3.33607 1.85977 4.45915 1.59771C4.72125 1.52284 5.02071 1.52284 5.28275 1.52284C7.75356 1.52284 10.2618 1.52284 12.7326 1.52284C13.8932 1.52284 14.8665 2.00952 15.6152 2.90799C16.1019 3.46954 16.3639 4.1434 16.4388 4.85469C16.4388 5.00444 16.4763 5.15418 16.4763 5.30392C16.4388 7.81217 16.4388 10.283 16.4388 12.7538Z"
            fill={color}
        />
        <path
            d="M15.0911 3.95617C15.0911 4.51772 14.6419 4.96695 14.0803 4.96695C13.5188 4.96695 13.0696 4.51772 13.0696 3.95617C13.0696 3.39463 13.5188 2.94539 14.0803 2.94539C14.6419 2.90795 15.0911 3.39463 15.0911 3.95617Z"
            fill={color}
        />
        <path
            d="M8.9516 4.29297C6.33101 4.29297 4.19714 6.42685 4.19714 9.0474C4.19714 11.668 6.33101 13.8018 8.9516 13.8018C11.5721 13.8018 13.7434 11.668 13.7434 9.0474C13.7434 6.42685 11.5721 4.29297 8.9516 4.29297ZM8.9516 12.4541C7.07973 12.4541 5.54486 10.9192 5.54486 9.0474C5.54486 7.17558 7.07973 5.64068 8.9516 5.64068C10.8234 5.64068 12.3583 7.17558 12.3583 9.0474C12.3583 10.9192 10.8608 12.4541 8.9516 12.4541Z"
            fill={color}
        />
    </svg>
);

export default Footer;
