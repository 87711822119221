import { connect } from "react-redux";

import Home from "./Home";

import { getEvent } from "../../actions/event/creators";
import { setFunction, deleteReservation } from "../../actions/event/index";
import { changeCurrency } from "../../actions/global";

const mapStateToProps = (state) => ({
  event: state.event.event,
  eventData: state.event.eventData,
  functions: state.event.functions,
  locations: state.event.locations,
  reservationSeats: state.event.reservationSeats,
  selectedFunction: state.event.selectedFunction,
  loading: state.event.loading,
  global: state.global,
});

const mapDispatchToProps = (dispatch) => ({
  getEvent: (eventId, selectedFunctionId) =>
    dispatch(getEvent(eventId, selectedFunctionId)),
  setFunction: (itemSelected) => {
    return  dispatch(setFunction(itemSelected))
  },
  deleteReservation: (itemSelected) =>
    dispatch(deleteReservation(itemSelected)),
    changeCurrency:(moneda)=>dispatch(changeCurrency(moneda))
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
