import React, { useEffect, useRef } from "react";
import { Formik, Field, ErrorMessage, Form } from "formik";
import { withTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const PhoneInputField = ({ field, form, ...props }) => {
    return (
        <PhoneInput
            country={props.cultura.toLowerCase()}
            masks={{
                cl: ".....-....",
                us: "...-...-....",
                ve: "...-...-....",
                es: "...-...-...",
            }}
            inputProps={{
                name: field.name,
                id: props.id,
            }}
            value={field.value}
            onChange={(phoneNumber) => {
                form.setFieldValue(field.name, phoneNumber);
                if (field.onChange !== null) {
                    field.onChange(phoneNumber);
                }
            }}
        />
    );
};
const RenderComponent = (props) => {
    if (props.component === "PhoneInput")
        return (
            <Field
                type="tel"
                id={props.id}
                name={props.name}
                component={PhoneInputField}
                cultura={props.cultura}
            />
        );
    else return <></>;
};
const DisplayFormikState = (props) => (
    <div style={{ margin: "1rem 0" }}>
        <h3 style={{ fontFamily: "monospace" }}>Formik State</h3>
        <pre
            style={{
                background: "#f6f8fa",
                fontSize: ".65rem",
                padding: ".5rem",
            }}
        >
            <strong>props</strong> = {JSON.stringify(props, null, 2)}
        </pre>
    </div>
);
const CITIES = [
    [
        "Aysén",
        "Chile Chico",
        "Cisnes",
        "Cochrane",
        "Coyhaique",
        "Guaitecas",
        "Lago Verde",
        "O'Higgins",
        "Río Ibáñez",
        "Tortel",
    ],
    [
        "Antofagasta",
        "Calama",
        "María Elena",
        "Mejillones",
        "Ollagüe",
        "San Pedro de Atacama",
        "Sierra Gorda",
        "Taltal",
        "Tocopilla",
    ],
    ["Arica", "Camarones", "General Lagos", "Putre"],
    [
        "Alto del Carmen",
        "Caldera",
        "Chañaral",
        "Copiapó",
        "Diego de Almagro",
        "Freirina",
        "Huasco",
        "Tierra Amarilla",
        "Vallenar",
    ],
    [
        "Alto Biobío",
        "Antuco",
        "Arauco",
        "Cabrero",
        "Cañete",
        "Chiguayante",
        "Concepción",
        "Contulmo",
        "Coronel",
        "Curanilahue",
        "Florida",
        "Hualpén",
        "Hualqui",
        "Laja",
        "Lebu",
        "Los Alamos",
        "Los Angeles",
        "Lota",
        "Mulchén",
        "Nacimiento",
        "Negrete",
        "Penco",
        "Quilaco",
        "Quilleco",
        "San Pedro de la Paz",
        "San Rosendo",
        "Santa Bárbara",
        "Santa Juana",
        "Talcahuano",
        "Tirúa",
        "Tomé",
        "Tucapel",
        "Yumbel",
    ],
    [
        "Andacollo",
        "Canela",
        "Combarbalá",
        "Coquimbo",
        "Illapel",
        "La Higuera",
        "La Serena",
        "Los Vilos",
        "Monte Patria",
        "Ovalle",
        "Paihuano",
        "Punitaqui",
        "Río Hurtado",
        "Salamanca",
        "Vicuña",
    ],
    [
        "Angol",
        "Carahue",
        "Cholchol",
        "Collipulli",
        "Cunco",
        "Curacautín",
        "Curarrehue",
        "Ercilla",
        "Freire",
        "Galvarino",
        "Gorbea",
        "Lautaro",
        "Loncoche",
        "Lonquimay",
        "Los Sauces",
        "Lumaco",
        "Melipeuco",
        "Nueva Imperia",
        "Padre Las Casas",
        "Perquenco",
        "Pitrufquén",
        "Pucón",
        "Purén",
        "Renaico",
        "Saavedra",
        "Temuco",
        "Teodoro Schmidt",
        "Toltén",
        "Traiguén",
        "Victoria",
        "Vilcún",
        "Villarrica",
    ],
    [
        "Chépica",
        "Chimbarongo",
        "Codegua",
        "Coínco",
        "Coltauco",
        "Doñihue",
        "Graneros",
        "La Estrella",
        "Las Cabras",
        "Litueche",
        "Lolol",
        "Machalí",
        "Malloa",
        "Marchihue",
        "Mostazal",
        "Nancagua",
        "Navidad",
        "Olivar",
        "Palmilla",
        "Paredones",
        "Peralillo",
        "Peumo",
        "Pichidegua",
        "Pichilemu",
        "Placilla",
        "Pumanque",
        "Quinta de Tilcoco",
        "Rancagua",
        "Rengo",
        "Requínoa",
        "San Fernando",
        "Santa Cruz",
        "San Vicente",
    ],
    [
        "Ancud",
        "Calbuco",
        "Castro",
        "Chaitén",
        "Chonchi",
        "Cochamó",
        "Curaco de Vélez",
        "Dalcahue",
        "Fresia",
        "Frutillar",
        "Futaleufú",
        "Hualaihué",
        "Llanquihue",
        "Los Muermos",
        "Maullín",
        "Osorno",
        "Palena",
        "Puerto Montt",
        "Puerto Octay",
        "Puerto Varas",
        "Puqueldón",
        "Purranque",
        "Puyehue",
        "Queilén",
        "Quellón",
        "Quemchi",
        "Quinchao",
        "Río Negro",
        "San Juan de la Costa",
        "San Pablo",
    ],
    [
        "Corral",
        "Futrono",
        "Lago Ranco",
        "Lanco",
        "La Unión",
        "Los Lagos",
        "Máfil",
        "Mariquina",
        "Paillaco",
        "Panguipulli",
        "Río Bueno",
        "Valdivia",
    ],
    [
        "Antártica",
        "Cabo de Hornos",
        "Laguna Blanca",
        "Puerto Natales",
        "Porvenir",
        "Primavera",
        "Punta Arenas",
        "Río Verde",
        "San Gregorio",
        "Timaukel",
        "Torres del Paine",
    ],
    [
        "Cauquenes",
        "Chanco",
        "Colbún",
        "Constitución",
        "Curepto",
        "Curicó",
        "Empedrado",
        "Hualañé",
        "Licantén",
        "Linares",
        "Longaví",
        "Maule",
        "Molina",
        "Parral",
        "Pelarco",
        "Pelluhue",
        "Pencahue",
        "Rauco",
        "Retiro",
        "Río Claro",
        "Romeral",
        "Sagrada Familia",
        "San Clemente",
        "San Javier",
        "San Rafael",
        "Talca",
        "Teno",
        "Vichuquén",
        "Villa Alegre",
        "Yerbas Buenas",
    ],
    [
        "Alhué",
        "Buin",
        "Calera de Tango",
        "Cerrillos",
        "Cerro Navia",
        "Colina",
        "Conchalí",
        "Curacaví",
        "El Bosque",
        "El Monte",
        "Estación Central",
        "Huechuraba",
        "Independencia",
        "Isla de Maipo",
        "La Cisterna",
        "La Florida",
        "La Granja",
        "Lampa",
        "La Pintana",
        "La Reina",
        "Las Condes",
        "Lo Barnechea",
        "Lo Espejo",
        "Lo Prado",
        "Macul",
        "Maipú",
        "María Pinto",
        "Melipilla",
        "Ñuñoa",
        "Padre Hurtado",
        "Paine",
        "Pedro Aguirre Cerda",
        "Peñaflor",
        "Peñalolén",
        "Pirque",
        "Providencia",
        "Pudahuel",
        "Puente Alto",
        "Quilicura",
        "Quinta Normal",
        "Recoleta",
        "Renca",
        "San Bernardo",
        "San Joaquín",
        "San José de Maipo",
        "San Miguel",
        "San Pedro de Melipilla",
        "San Ramón",
        "Santiago",
        "Talagante",
        "Tiltil",
        "Vitacura",
    ],
    [
        "Bulnes",
        "Chillán",
        "Chillán Viejo",
        "Cobquecura",
        "Coelemu",
        "Coihueco",
        "El Carmen",
        "Ninhue",
        "Ñiquén",
        "Pemuco",
        "Pinto",
        "Portezuelo",
        "Quillón",
        "Quirihue",
        "Ránquil",
        "San Carlos",
        "San Fabián",
        "San Ignacio",
        "San Nicolás",
        "Treguaco",
        "Yungay",
    ],
    [
        "Alto Hospicio",
        "Camiña",
        "Colchane",
        "Huara",
        "Iquique",
        "Pica",
        "Pozo Almonte",
    ],
    [
        "Algarrobo",
        "Cabildo",
        "Calera",
        "Calle Larga",
        "Cartagena",
        "Casablanca",
        "Catemu",
        "Concón",
        "El Quisco",
        "El Tabo",
        "Hijuelas",
        "Isla de Pascua",
        "Juan Fernández",
        "La Cruz",
        "La Ligua",
        "Limache",
        "Llaillay",
        "Los Andes",
        "Nogales",
        "Olmué",
        "Panquehue",
        "Papudo",
        "Petorca",
        "Puchuncaví",
        "Putaendo",
        "Quillota",
        "Quilpué",
        "Quintero",
        "Rinconada",
        "San Antonio",
        "San Esteban",
        "San Felipe",
        "Santa María",
        "Santo Domingo",
        "Valparaíso",
        "Villa Alemana",
        "Viña del Mar",
        "Zapallar",
    ],
];
const RenderSelect = ({ id, name, data, defaultText, t }) => {
    return (
        <Field
            name={name}
            id={id}
            render={(props) => {
                const { field } = props;
                const defaultOption = (
                    <option key="default" value="">
                        {t(defaultText)}
                    </option>
                );
                const options = data.map((val, index) => (
                    <option key={index} value={val.id}>
                        {val.text}
                    </option>
                ));
                const selectOptions = [defaultOption, ...options];
                return (
                    <>
                        <select value={field.value} {...field} id={id}>
                            {selectOptions}
                        </select>
                        <i className="icon-angle-d color-ppal"></i>
                    </>
                );
            }}
        />
    );
};
const RenderSelectCity = ({ id, name, defaultText, t }) => {
    let region = document.getElementsByName("region")[0]?.value || 0;
    // console.log(region);
    return region ? (
        parseInt(region) !== 17 ? (
            <Field
                name={name}
                render={(props) => {
                    const { field } = props;
                    const defaultOption = (
                        <option key="default" value="">
                            Seleccionar Ciudad
                        </option>
                    );
                    const options = CITIES[parseInt(region) - 1].map(
                        (val, index) => (
                            <option key={index} value={val}>
                                {val}
                            </option>
                        )
                    );
                    const selectOptions = [defaultOption, ...options];
                    return (
                        <>
                            <select value={field.value} {...field} id={id}>
                                {selectOptions}
                            </select>
                            <i className="icon-angle-d color-ppal"></i>
                        </>
                    );
                }}
            />
        ) : (
            <Field name={name}>
                {({ field }) => <input id={id} type="text" {...field} />}
            </Field>
        )
    ) : (
        <Field name={name}>
            {({ field }) => <input id={id} type="text" {...field} />}
        </Field>
    );
};
const CustomerForm = (props) => {
    const { rowsFields, validCounter, validateForm, t, subCultura } = props;

    // console.log(rowsFields);

    const initialValues = { nacionalidad: "V" };

    const getInitialValues = () => {
        rowsFields.forEach((inputs) => {
            inputs.forEach((field) => {
                if (!initialValues[field.name]) {
                    initialValues[field.name] = field.value || "";
                }
            });
        });
    };
    const render = (errors, touched) => {
        return rowsFields.map((row, index) => {
            return (
                <div key={index}>
                    {row.map((input) => {
                        return (
                            <div
                                key={input.name}
                                className={input.classContainer}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        flex: 1,
                                    }}
                                    className={`wrap-input ${
                                        errors[input.name] &&
                                        touched[input.name]
                                            ? "error"
                                            : ""
                                    }`}
                                >
                                    <label>
                                        {t(input.label)}
                                        <sup className="color-ppal">*</sup>
                                    </label>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                        }}
                                    >
                                        {input.type === "component" ? (
                                            <RenderComponent
                                                name={input.name}
                                                id={input.id}
                                                component={input.component}
                                                cultura={subCultura || "VE"}
                                            />
                                        ) : input.type === "select" ? (
                                            <RenderSelect {...input} t={t} />
                                        ) : input.type === "cedula" ? (
                                            <div className="wrap-cedula">
                                                <RenderSelect
                                                    name="nacionalidad"
                                                    id="nacionalidad"
                                                    defaultText=""
                                                    data={[
                                                        { id: "V", text: "V" },
                                                        { id: "E", text: "E" },
                                                    ]}
                                                    t={t}
                                                />
                                                <Field name={"cedula"}>
                                                    {({ field }) => (
                                                        <input
                                                            id={"cedula"}
                                                            type="text"
                                                            {...field}
                                                            onKeyDown={(
                                                                event
                                                            ) => {
                                                                if (
                                                                    (event.keyCode <
                                                                        48 ||
                                                                        event.keyCode >
                                                                            57) &&
                                                                    (event.keyCode <
                                                                        96 ||
                                                                        event.keyCode >
                                                                            105) &&
                                                                    event.keyCode !==
                                                                        190 &&
                                                                    event.keyCode !==
                                                                        110 &&
                                                                    event.keyCode !==
                                                                        8 &&
                                                                    event.keyCode !==
                                                                        9
                                                                ) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                        />
                                                    )}
                                                </Field>
                                            </div>
                                        ) : input.id === "city" &&
                                          props.infoCom === "4" ? (
                                            <RenderSelectCity
                                                {...input}
                                                t={t}
                                            />
                                        ) : (
                                            <Field name={input.name}>
                                                {({ field }) => (
                                                    <input
                                                        id={input.id}
                                                        type="text"
                                                        {...field}
                                                    />
                                                )}
                                            </Field>
                                        )}
                                        <ErrorMessage
                                            name={input.name}
                                            component="span"
                                            className="errorText"
                                        />
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            );
        });
    };

    const validate = (values) => {
        const errors = {};
        Object.keys(values).forEach((key) => {
            if (!values[key]) errors[key] = t("required");
            if (values[key]) {
                switch (key) {
                    case "email":
                        if (
                            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                                values[key]
                            )
                        )
                            errors[key] = t("invalidEmail");
                        break;
                    case "repeatEmail":
                        if (
                            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                                values[key]
                            ) ||
                            values[key] !== values.email
                        )
                            errors[key] = t("invalidEmail");
                        break;
                    case "phoneNumber":
                        if (values[key].length < 9)
                            errors[key] = `${t("validationLength")} 9`;
                        break;
                    case "region":
                        if (values[key].length < 1)
                            errors[key] = t("selectRegion");
                        break;
                    case "firstName":
                    case "lastName":
                    case "address":
                    case "city":
                    case "zipCode":
                        if (values[key].length < 2)
                            errors[key] = `${t("validationLength")} 2`;
                        break;
                    default:
                        break;
                }
            }
        });
        validateForm(values, Object.keys(errors).length === 0);
        return errors;
    };

    getInitialValues();
    const formRef = useRef();
    useEffect(() => {
        if (formRef.current && validCounter > 0) {
            formRef.current.validateForm().then((validation) => {
                formRef.current.setTouched(validation);
                if (Object.keys(validation).length !== 0) {
                    window.scrollTo(0, formRef.current.offsetTop);
                }
            });
        }
    }, [validCounter]);

    return (
        <div className="wrap-content">
            <Formik
                onSubmit={(values, { setSubmitting }) => {
                    //alert(values);
                    //onSubmitForm(values, { setSubmitting });
                }}
                validate={validate}
                initialValues={initialValues}
                innerRef={formRef}
            >
                {({ values, errors, touched }) => (
                    <div className="datos-cli">
                        <section>
                            <Form>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                >
                                    {render(errors, touched)}
                                </div>
                            </Form>
                        </section>
                    </div>
                )}
            </Formik>
        </div>
    );
};

export default withTranslation()(CustomerForm);
