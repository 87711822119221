import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import './MapContainer.css';

const mapStyles = {
  width: '100%',
  height: '100%',
};

const displayMarkers = (locations, selectedFunction) => {
  if (!selectedFunction) {
    return locations.map((location, index) => {
      let locs = location.split(',');
      return (
        <Marker
          key={index}
          id={index}
          position={{
            lat: parseFloat(locs[0]),
            lng: parseFloat(locs[1]),
          }}
        />
      );
    });
  } else {
    let locs = selectedFunction.GoogleMaps
      ? selectedFunction.GoogleMaps.split(',')
      : { lat: 0, lng: 0 };
    return (
      <Marker
        position={{
          lat: parseFloat(locs[0]),
          lng: parseFloat(locs[1]),
        }}
      />
    );
  }
};

const MapContainer = ({
  locations,
  isMultiCity,
  selectedFunction,
  eventData,
  google,
}) => {
  const { t } = useTranslation();
  let bounds = new google.maps.LatLngBounds();
  let boundsLocation = [];
  let SelectedLocation = '';
  const locs = locations.length > 0 ? locations[0].split(',') : [0, 0];
  locations.forEach((element) => {
    let locs = element.split(',');
    if (locs) {
      boundsLocation.push({
        lat: parseFloat(locs[0]),
        lng: parseFloat(locs[1]),
      });
    }
  });

  if (boundsLocation) {
    for (var i = 0; i < boundsLocation.length; i++) {
      bounds.extend(boundsLocation[i]);
    }
  }

  return (
    <>
      <p className="color-texto-contenido hidden-xs visible-md">
        {locations.length > 1 && isMultiCity && !selectedFunction
          ? t('multipleLocations')
          : selectedFunction
          ? `${selectedFunction.Calle1 || ''} ${selectedFunction.Calle2 || ''}`
          : `${eventData.address}`}
      </p>
      <div id="mapContainer">
        <Map
          google={google}
          mapTypeControl={false}
          streetViewControl={false}
          fullscreenControl={false}
          style={mapStyles}
          zoom={20}
          bounds={bounds}
            center={{
              lat: parseFloat(locs[0]),
              lng: parseFloat(locs[1]),
            }}
            initialCenter={{
              lat: parseFloat(locs[0]),
              lng: parseFloat(locs[1]),
            }}
        >
          {displayMarkers(locations, selectedFunction)}
        </Map>
      </div>
    </>
  );
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_API,
})(MapContainer);
