import {
  GET_EVENT_START,
  GET_EVENT_SUCCESS,
  GET_EVENT_ERROR,
  GET_EVENT_FUNCTIONS_START,
  GET_EVENT_FUNCTIONS_SUCCESS,
  GET_EVENT_FUNCTIONS_ERROR,
  SET_EVENT_FUNCTION,
  SET_EVENT_RESERVATION,
  DELETE_EVENT_RESERVATION,
  SET_EVENT_RESUME_TICKETS,
  SET_EVENT_TOTAL_MOUNT,
  RESET_EVENT,
  CHANGE_BLOQUED_RESERVATION,
  REFRESH_RESERVATION
} from './types';

export const resetEvent = () => ({
  type: RESET_EVENT,
});
export const getEventStarted = () => {
  // console.log("inicio evento")
  return ({
  type: GET_EVENT_START,
})
};

export const getEventSuccess = (event) => ({
  type: GET_EVENT_SUCCESS,
  payload: {
    event,
  },
});

export const getEventError = (message) => ({
  type: GET_EVENT_ERROR,
  payload: {
    message,
    variant: 'error',
  },
});

export const getEventFunctiosStarted = () => ({
  type: GET_EVENT_FUNCTIONS_START,
});

export const getEventFunctionsSuccess = (data, selectedFunctionId, notSetFunct = true) => ({
  type: GET_EVENT_FUNCTIONS_SUCCESS,
  payload: {
    eventData: {
      from: data.Desde,
      to: data.Hasta,
      priceFrom: data.PrecioDesde,
      priceFromUsd: data.PrecioDesdeUsd,
      address: data.Direccion,
      currencySymbol: data.Moneda,
      currencyMulti: data.MultiplesMonedas,
      city: data.Ciudad,
      venue: data.Venue,
    },
    loading: false,
    locations: data.Locaciones,
    functions: data.Funciones,
    selectedFunction:
      data.Funciones.length === 1 && notSetFunct
        ? data.Funciones[0]
        : selectedFunctionId
        ? data.Funciones.find((f) => {
            return f.FuncionId === parseInt(selectedFunctionId);
          })
        : null,
  },
});

export const getEventFunctionsError = (message) => ({
  type: GET_EVENT_FUNCTIONS_ERROR,
  payload: {
    message,
    variant: 'error',
  },
});

export const setFunction = (item) => {
  return {
    type: SET_EVENT_FUNCTION,
    payload: {
      selectedFunction: item,
    },
  };
};

export const setReservation = (item) => {
  // console.log(item)
  return {
    type: SET_EVENT_RESERVATION,
    payload: {
      reservationSeats: item,
    },
  };
};

export const deleteReservation = (item) => {
  return {
    type: DELETE_EVENT_RESERVATION,
    payload: {
      deletedReservation: item,
    },
  };
};
export const changeBloquedToReserve = (tickets) => {
  return {
    type: CHANGE_BLOQUED_RESERVATION,
    payload: {
      changeBloquedToReserve: tickets,
    }
  };
};
export const refreshReservation = (reservaciones) => {
  return {
    type: REFRESH_RESERVATION,
    payload:{
      refreshReservation:reservaciones 
    }
  };
};

export const setResumeTickets = (item) => {
  return {
    type: SET_EVENT_RESUME_TICKETS,
    payload: {
      resumeTickets: item,
    },
  };
};

export const setTotalMount = (mount) => {
  return {
    type: SET_EVENT_TOTAL_MOUNT,
    payload: {
      totalMount: mount,
    },
  };
};
