import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.scss";
import { Place, Share } from "../../../utils/iconsSvg";
import ReactPlayer from "react-player";

SwiperCore.use([Navigation]);
const EventBanner = (props) => {
    const [heightVideos, setHeightVideos] = useState(0);
    const { t } = useTranslation();
    const [controls, setControls] = useState(true);

    const {
        eventId,
        event,
        eventData,
        locationsNum,
        selectedFunction,
        isMultiCity,
        hourFormat,
        shortUrl,
        setShowModal,
    } = props;
    const refCarrusel = useRef();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const HandleHeight = () => {
        if (!refCarrusel.current) return;
        const img = refCarrusel?.current.getElementsByTagName("img")[0];
        if (img && img.getBoundingClientRect().height !== heightVideos) {
            setHeightVideos(img.getBoundingClientRect().height);
        }
    };
    useEffect(() => {
        window.addEventListener("resize", HandleHeight);
        return () => {
            window.removeEventListener("resize", HandleHeight);
        };
    }, []);
    useEffect(() => {
        HandleHeight();
    }, [HandleHeight]);

    const selectedLabel =
        selectedFunction && selectedFunction.Extra ? (
            <div id="labelSelect" className="label">
                {selectedFunction.Extra}
            </div>
        ) : null;
    let video = event.VideoUrl || "";
    video = video.replace("watch?v=", "embed/");

    return (
        <>
            <div className="banner-evento clear-fl">
                <div className="Carrusel-banner" ref={refCarrusel}>
                    <Swiper
                        spaceBetween={10}
                        slidesPerView={1}
                        navigation={controls}

                        // breakpoints={{
                        //     768: {
                        //         slidesPerView: 2,
                        //         spaceBetween: 30
                        //     },
                        //     1024: {
                        //         slidesPerView: 2,
                        //         spaceBetween: 30
                        //     }
                        // }}
                    >
                        <SwiperSlide>
                            <figure>
                                <img
                                    src={
                                        `https://ticketmundoblob.blob.core.windows.net/imagenestmusaprueba/Evento_${eventId}_517_262.jpeg` ||
                                        "/images/evento/img-evento.jpg"
                                    }
                                    alt="Imagen Evento"
                                />
                            </figure>
                        </SwiperSlide>
                        {video !== "" ? (
                            <SwiperSlide>
                                <ReactPlayer
                                    url={video}
                                    className="react-player"
                                    width="100%"
                                    height={heightVideos}
                                    controls={true}
                                    autoplay={false}
                                    onPlay={() => {
                                        setControls(false);
                                    }}
                                    onPause={() => {
                                        setControls(true);
                                    }}
                                    onEnded={() => {
                                        setControls(true);
                                    }}
                                />
                            </SwiperSlide>
                        ) : null}
                    </Swiper>
                    <button
                        className="share-button"
                        onClick={() => {
                            shortUrl();
                            setShowModal(true);
                        }}
                    >
                        <Share />
                    </button>
                </div>
                <div className="info-evento color-titulos">
                    <div className="title sub-title-bold ">{event.Nombre}</div>
                    {locationsNum > 1 ? (
                        selectedFunction ? (
                            <p className="tipo-funcion">
                                {selectedFunction.RecintoNombre}
                            </p>
                        ) : (
                            <p className="tipo-funcion">
                                {t("multipleLocations")}
                            </p>
                        )
                    ) : (
                        <p className="tipo-funcion">
                            <span>{eventData.venue}</span>
                        </p>
                    )}

                    {props.event.Categorias && props.event.Categorias.length > 0 ? (
                        props.event.Categorias.map((c) => (
                        <span className="label">{c.Nombre}</span>
                        ))
                    ) : (
                        <span className="label">{event.CategoriaNombre}</span>
                    )}
                    {selectedLabel}

                    {selectedFunction ? (
                        <div id="fecha-r" className="active">
                            <span>
                                {moment
                                    .months()

                                    [
                                        moment(selectedFunction.Fecha).month()
                                    ].toLocaleUpperCase()
                                    .substring(0, 3)}
                            </span>
                            <p>{moment(selectedFunction.Fecha).date()}</p>
                        </div>
                    ) : eventData.from ? (
                        <div id="fecha-r" className="active">
                            <div>Desde</div>
                            <span>
                                {moment
                                    .months()

                                    [
                                        moment(eventData.from).month()
                                    ].toLocaleUpperCase()
                                    .substring(0, 3)}
                            </span>
                            <p>{moment(eventData.from).date()}</p>
                        </div>
                    ) : null}
                </div>
            </div>
        </>
    );
};

export default EventBanner;
