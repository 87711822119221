import React, { useEffect, useState } from "react";
import Services from "../../../services/Services";
import Header from "../../../components/Header/Header";
import { withTranslation } from "react-i18next";
import { SkeletonConfirmation } from "../../../components/Skeleton";
const Confirmation = (props) => {
    const { global, t } = props;
    const [confirmacion, setConfirmacion] = useState(null);
    const [loading, setLoading] = useState(true);
    const [org, setOrg] = useState(null);
    useEffect(() => {
        if (confirmacion && confirmacion.Orden) {
            setLoading(false);
        }
    }, [confirmacion]);
    useEffect(() => {
        const { transaccion } = props.match.params;
        let obj = props.location.state
            ? props.location.state.confirmacion
            : null;
        if (obj === null || obj === undefined) {
            (async () => {
                const {
                    status,
                    data,
                } = await Services.pay.obtenerConfirmacionSencilla(transaccion);
                if (status === 200 && data) {
                    setConfirmacion({ ...data });
                } else {
                    //error cargando
                }
            })();
        }
        setConfirmacion(obj ? { ...obj } : null);
        if (props.location.state && props.location.state.org) {
            // console.log(props.location.state.org);
            setOrg(props.location.state.org);
        }
    }, []);
    return (
        <>
            {global.loading || loading ? (
                <SkeletonConfirmation />
            ) : (
                <>
                    {/* <Header global={global} confirmation={true}/> */}
                    <section id="main" className="mainE">
                        <div className="container-custom-tm">
                            {confirmacion && confirmacion.Orden && (
                                <div className="wrap-evento confirmacion-donacion clear-fl">
                                    {global.Idioma.toLowerCase() === "es" && (
                                        <div className="title sub-title-bold color-titulos size-32 mb-30">
                                            {org && org.confirmacionTitle
                                                ? org.confirmacionTitle
                                                : t("thanksDonation")}
                                        </div>
                                    )}

                                    {global.Idioma.toLowerCase() === "en" && (
                                        <div className="title sub-title-bold color-titulos size-32 mb-30">
                                            {org && org.confirmacionTitle_en
                                                ? org.confirmacionTitle_en
                                                : t("thanksDonation")}
                                        </div>
                                    )}

                                    {global.Idioma.toLowerCase() === "es" && (
                                        <div className="mb-30 size-18">
                                            {org && org.confirmacionText
                                                ? org.confirmacionText
                                                : t("donationProcess")}
                                        </div>
                                    )}

                                    {global.Idioma.toLowerCase() === "en" && (
                                        <div className="mb-30 size-18">
                                            {org && org.confirmacionText_en
                                                ? org.confirmacionText_en
                                                : t("donationProcess")}
                                        </div>
                                    )}
                                    <button
                                        className="mb-30 btn-skin btn-get-tk btn-ppal active"
                                        onClick={() =>
                                            (window.location = global.inicio)
                                        }
                                    >
                                        {t("continue")}
                                    </button>
                                </div>
                            )}
                        </div>
                    </section>
                </>
            )}
        </>
    );
};

export default withTranslation()(Confirmation);
