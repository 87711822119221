import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Timer from './Timer';

const mapStateToProps = state => ({
  global: state.global,
  event: state.event.event,
});

const mapDispatchToProps = dispatch => ({

});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Timer));